import {
    LOADALLHOLIDAY,
    CHANGEHOLIDAY,
    initialState,
    LOADHOLIDAY,
    RESETHOLIDAY
} from "../constant/constant";

export default function reducer(state = { ...initialState.holiday }, action) {

    switch (action.type) {
        case LOADALLHOLIDAY:

            return { ...state, holidays: [...action.holidays] }

        case LOADHOLIDAY:
            return { ...state, holiday: { ...action.holiday } }

        case CHANGEHOLIDAY:
            return { ...state, holiday: { ...state.holiday, [action.name]: action.value } };

        case RESETHOLIDAY:
            return { ...state, holiday: { ...initialState.holiday.holiday }};
        default:
            return { ...state };
    }
}