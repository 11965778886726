import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Modal, Card, Row, Col, Badge, Button, Form } from "react-bootstrap";
import {
    approvePendingService, loadHighlightChartService, loadHighlightService,
     loadPendingService, loadSummaryReport,
    rejectPendingService
} from "../action/reportAction";
import { notification } from "../action/notificationAction";
import Payment from "./Payment";
import { format } from "date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    LineElement,
    PointElement,
    Legend,
} from "chart.js";
import Invoice from "./Invoice";
import Expense from "./Expense";
import { formatAccounting } from "../constant/format";
import { UPDATE_DASH_SPREAD, UPDATE_DASH_PENDING, UPDATE_DASH_SPREAD_CLOSED } from "../constant/constant";
import CustomLoader from "./CustomLoader";
import { loadAttendace, saveAttendanceService } from "../action/attendanceAction";
import Attendance from "./Employee/Attendance";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement
);


export default function Dashboard({ permitted, setUserAttPopup, userAttPopup }) {

    const dispatch = useDispatch();

    const getCurrentFinancialYear = () => {
        let fiscalyear = "";
        const today = new Date();
        if ((today.getMonth() + 1) <= 3) {
            fiscalyear = (today.getFullYear() - 1)
        } else {
            fiscalyear = today.getFullYear()
        }
        return fiscalyear
    }

    const startYear = getCurrentFinancialYear() + 1;
    const endYear = getCurrentFinancialYear() - 4;


    // eslint-disable-next-line no-unused-vars
    const [insight, setInsight] = React.useState([]);
    const [hightlight, setHightlight] = React.useState([]);


    const [showSummary, setShowSummary] = React.useState(true);
    const [showPending, setShowPending] = React.useState(true);
    const [showPendingExpense, setShowPendingExpense] = React.useState(true);
    const [showDummy, setShowDummy] = React.useState(true);
    const [showHigh, setShowHigh] = React.useState(true);
    const [showChart, setShowChart] = React.useState(false);
    const [showChartClosed, setShowChartClosed] = React.useState(false);
    const [showFullscreen, setShowFullscreen] = React.useState(false);
    const [showAction, setAction] = React.useState(true);
    const [showInsight, setShowInsight] = React.useState(false);
    const [selected, setSelected] = React.useState(null);

    const [summaryYear, setSummaryYear] = React.useState('0');

    const [showModal, setShowModal] = React.useState(false);
    const [hoverProfit, setHoverProfit] = React.useState(false);
    const [hoverCash, setHoverCash] = React.useState(false);
    const [attMode, setAttMode] = React.useState(null);

    const summary = useSelector(
        (state) => state.report.summary
    );

    // const [location, setLocation] = React.useState(null);

    // eslint-disable-next-line no-unused-vars
    const [summaryPermission, setSummaryPermission] = React.useState(permitted(`DASHBOARD_SUMMARY`));
    // eslint-disable-next-line no-unused-vars
    const [pendingApprovePermission, setPendingApprovePermission] = React.useState(permitted(`DASHBOARD_PENDING_APPROVE`));
    // eslint-disable-next-line no-unused-vars
    const [pendingViewAllPermission, setPendingViewAllPermission] = React.useState(permitted(`DASHBOARD_PENDING_VIEW_ALL`));
    // eslint-disable-next-line no-unused-vars
    const [pendingViewPermission, setPendingViewPermission] = React.useState(permitted(`DASHBOARD_PENDING_VIEW`));
    // eslint-disable-next-line no-unused-vars
    const [dummyViewPermission, setDummyViewPermission] = React.useState(permitted(`DASHBOARD_DUMMY_VIEW`));
    // eslint-disable-next-line no-unused-vars
    const [highlightPermission, setHighlightPermission] = React.useState(permitted(`DASHBOARD_HIGHLIGHT`));
    // eslint-disable-next-line no-unused-vars
    const [highlightChartPermission, setHighlightChartPermission] = React.useState(permitted(`DASHBOARD_HIGHLIGHT_CHART`));
    // eslint-disable-next-line no-unused-vars
    const [insightPermission, setInsightPermission] = React.useState(permitted(`DASHBOARD_INSIGHT`));
    // eslint-disable-next-line no-unused-vars
    const [pendDeletePermission, setPendDeletePermission] = React.useState(permitted(`DASHBOARD_PENDING_DELETE`));

    const costcenter = useSelector(
        (state) => state.costcenter.costcenter
    );


    const highlightChart = useSelector(
        (state) =>
            state.dashboard.highlightChart
    );

    const highlightChartClosed = useSelector(
        (state) =>
            state.dashboard.highlightChartClosed
    );


    const request = useSelector(
        (state) =>
            state.dashboard.request
    );

    const theme = useSelector((state) => state.ThemeOptions);


    const options = {
        // indexAxis: 'x',
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                // ticks: {
                //     min: 0, 
                //     callback: function (value, index, values) {
                //       return Number((value/1000).toString())+'K'; 
                //     }
                //   },
            },
        },
    };

    

    React.useEffect(() => {

        if (insightPermission) {

            // loadInsightService().then((res) => {
            //     setInsight(res)
            // })
            //     .catch(err => {
            //         setInsight([]);
            //         dispatch(notification(err))
            //     });
        }

        if (highlightPermission) {

            loadHighlightService().then((res) => {
                setHightlight(res);
                if (res.length === 0) {
                    setShowHigh(false);
                }
            })
                .catch(err => {
                    setHightlight([]);
                    dispatch(notification(err))
                });
        }

        dispatch(loadAttendace())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pendingViewPermission, pendingViewAllPermission, insightPermission, highlightPermission]);


    const loadSpread = React.useCallback((costcenter) => {

        loadHighlightChartService(costcenter, 'Y').then((res) => {

            if (res) {
                let labels = [...new Set(res.map(e => e.name))];
                const filtered = [];

                labels.forEach(l => {

                    const sum = res.filter(e => e.name === l).map(e => Number(e.gross)).reduce((a, b) => a + b, 0);

                    if (sum > 0) {
                        filtered.push(l);
                    }
                });

                res = res.filter(e => filtered.includes(e.name));
                labels = [...new Set(res.map(e => e.name))];

                dispatch({
                    type: UPDATE_DASH_SPREAD, value: {
                        labels,
                        datasets: [

                            {
                                label: "Sales",
                                data: res.filter(e => e.side === 'Client').map(e => e.gross),
                                backgroundColor: "rgb(255, 99, 132)",
                                stack: "Stack 1",
                            },
                            {
                                label: "Received",
                                data: res.filter(e => e.side === 'Received').map(e => 1 * e.gross),
                                backgroundColor: "rgb(244, 209, 96)",
                                stack: "Stack 0",
                            },
                            {
                                label: "Purchase",
                                data: res.filter(e => e.side === 'Vendor').map(e => e.gross),
                                backgroundColor: "rgb(75, 192, 192)",
                                stack: "Stack 3",
                            },

                            {
                                label: "Paid",
                                data: res.filter(e => e.side === 'Paid').map(e => 1 * e.gross),
                                backgroundColor: "rgb(53, 162, 235)",
                                stack: "Stack 2",
                            },



                            {
                                label: "Labour",
                                data: res.filter(e => e.side === 'Labour').map(e => e.gross),
                                backgroundColor: "rgb(179, 200, 144)",
                                stack: "Stack 4",
                            },
                            {
                                label: "Expense",
                                data: res.filter(e => e.side === 'Expense').map(e => e.gross),
                                backgroundColor: "rgb(150, 126, 118)",
                                stack: "Stack 4",
                            },
                        ],
                    }
                })


            }
            else {
                dispatch({ type: UPDATE_DASH_SPREAD, value: null });
            }
        })
            .catch(err => {
                dispatch(notification(err))
                dispatch({ type: UPDATE_DASH_SPREAD, value: null });
            }
            );

    }, [dispatch]);

    const loadSpreadClosed = React.useCallback((costcenter) => {

        loadHighlightChartService(costcenter, 'N').then((res) => {

            if (res) {
                let labels = [...new Set(res.map(e => e.name))];
                const filtered = [];

                labels.forEach(l => {

                    const sum = res.filter(e => e.name === l).map(e => Number(e.gross)).reduce((a, b) => a + b, 0);

                    if (sum > 0) {
                        filtered.push(l);
                    }
                });

                res = res.filter(e => filtered.includes(e.name));
                labels = [...new Set(res.map(e => e.name))];

                dispatch({
                    type: UPDATE_DASH_SPREAD_CLOSED, value: {
                        labels,
                        datasets: [

                            {
                                label: "Sales",
                                data: res.filter(e => e.side === 'Client').map(e => e.gross),
                                backgroundColor: "rgb(255, 99, 132)",
                                stack: "Stack 1",
                            },
                            {
                                label: "Received",
                                data: res.filter(e => e.side === 'Received').map(e => 1 * e.gross),
                                backgroundColor: "rgb(244, 209, 96)",
                                stack: "Stack 0",
                            },
                            {
                                label: "Purchase",
                                data: res.filter(e => e.side === 'Vendor').map(e => e.gross),
                                backgroundColor: "rgb(75, 192, 192)",
                                stack: "Stack 3",
                            },

                            {
                                label: "Paid",
                                data: res.filter(e => e.side === 'Paid').map(e => 1 * e.gross),
                                backgroundColor: "rgb(53, 162, 235)",
                                stack: "Stack 2",
                            },



                            {
                                label: "Labour",
                                data: res.filter(e => e.side === 'Labour').map(e => e.gross),
                                backgroundColor: "rgb(179, 200, 144)",
                                stack: "Stack 4",
                            },
                            {
                                label: "Expense",
                                data: res.filter(e => e.side === 'Expense').map(e => e.gross),
                                backgroundColor: "rgb(150, 126, 118)",
                                stack: "Stack 4",
                            },
                        ],
                    }
                })


            }
            else {
                dispatch({ type: UPDATE_DASH_SPREAD_CLOSED, value: null });
            }
        })
            .catch(err => {
                dispatch(notification(err))
                dispatch({ type: UPDATE_DASH_SPREAD_CLOSED, value: null });
            }
            );

    }, [dispatch]);


    const loadPending = React.useCallback((costcenter) => {
        loadPendingService({ 'all': pendingViewAllPermission, costcenter }).then((res) => {
            dispatch({ type: UPDATE_DASH_PENDING, value: res });
            if (res.length === 0) {
                setShowPending(false);
                setShowPendingExpense(false);
            }

        })
            .catch(err => {
                dispatch({ type: UPDATE_DASH_PENDING, value: null });
                dispatch(notification(err))
            });

    }, [dispatch, pendingViewAllPermission]);

    React.useEffect(() => {

        if (summaryPermission && costcenter != null) {

            let fromDate, toDate;
            if (Number(summaryYear) !== 0) {
                fromDate = `${Number(summaryYear) - 1}-04-01`
                toDate = `${Number(summaryYear)}-03-31`
            }

            dispatch(loadSummaryReport(null, fromDate, toDate, costcenter));
            // loadProfitPercentage(costcenter, summaryYear);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryYear, dispatch, summaryPermission, costcenter]);


    React.useEffect(() => {

        if (highlightChartPermission && costcenter != null) {
            loadSpread(costcenter);
            loadSpreadClosed(costcenter);
        }

    }, [highlightChartPermission, loadSpread, loadSpreadClosed, costcenter]);



    React.useEffect(() => {
        if ((pendingViewPermission || pendingViewAllPermission) && costcenter != null) {
            loadPending(costcenter);
        }
    }, [loadPending, costcenter, pendingViewPermission, pendingViewAllPermission]);


  

    const rejectRequest = (id) => {
        rejectPendingService(id).then((res) => {
            dispatch(notification({ message: res, variant: 'success' }));
            loadPending()
        })
            .catch(err => {
                dispatch(notification(err))
            });
    }

    // const deleteRequest = (id) => {
    //     deletePendingService(id).then((res) => {
    //         dispatch(notification({ message: res, variant: 'success' }));
    //         loadPending()
    //     })
    //         .catch(err => {
    //             dispatch(notification(err))
    //         });
    // }

    const approveRequest = (id) => {
        approvePendingService(id).then((res) => {
            dispatch(notification({ message: res, variant: 'success' }));
            loadPending()
        })
            .catch(err => {
                dispatch(notification(err))
            });
    }

    const handleModalClose = () => {

        setSelected(null);
        setShowModal(false);
        loadPending(costcenter);

    }

  




    

    return (
        <Container fluid className="dashboard pb-3">


            <Row className="m-auto d-flex">

                {
                    summaryPermission || highlightChartPermission ?
                        <Col className="p-0 col-dash pe-1 order-1" lg={8} xl={showFullscreen ? 10 : 8} md={12}>
                            <Row className="m-auto d-flex p-0">


                                {
                                    summaryPermission && !showFullscreen ?
                                        <Col className="p-0 col-dash pe-1 " sm={12}>
                                            <Card className={`border  mt-2 ${showSummary ? 'show' : ''}`} >
                                                <Card.Title className="p-2 text-muted mb-0 d-flex ">
                                                    <b className="float-start pe-2"><i className="fa fa-bank pe-2 text-success pt-2" ></i> Summary</b>
                                                    <select className="form-control w-auto" value={summaryYear}
                                                        onChange={e => { setSummaryYear(e.target.value) }}
                                                    >
                                                        <option value="0">All</option>
                                                        {
                                                            [...Array(10)].filter((e, i) => startYear - i > endYear).map((e, i) =>

                                                                <option key={`year_${i}`} value={startYear - i}>{startYear - (i + 1)} - {startYear - i}</option>

                                                            )
                                                        }
                                                    </select>

                                                    <div className="ms-auto">
                                                        <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showSummary ? 'up' : 'down'}`} role="button" onClick={() => setShowSummary(!showSummary)}></i>
                                                    </div>
                                                </Card.Title>

                                                <Card.Body className={`overflow-auto dash-summary `} style={{ display: showSummary ? 'block' : 'none' }}>

                                                    <Container fluid className="p-1">

                                                        <Row className="mb-1 g-2">
                                                            <Col sm={12} md={6}  >

                                                                <Card>
                                                                    <Card.Body className={`border border-${summary?.profit > 0 ? 'success' : 'danger'}`}>
                                                                        <Card.Title className="text-center h6 mb-0">
                                                                            <Row className={`${summary?.profit > 0 ? 'text-success' : 'text-danger'} p-2 `} style={{ fontSize: '1.2rem' }}>
                                                                                <Col sm={12} md={9} className={`text-center fw-bold  ${hoverProfit ? 'active' : ''} border-end`} title="client.basic-(vendor.basic+expense)" onMouseOver={() => { setHoverProfit(true) }} onMouseLeave={() => { setHoverProfit(false) }}> &#x20b9; {formatAccounting(summary ? summary.profit : 0)} </Col>
                                                                                <Col sm={12} md={3} className={`text-center fw-bold  ${hoverProfit ? 'active' : ''}`}> {formatAccounting(summary ? summary.profitpercentage > 100 ? 100 : summary.profitpercentage < -100 ? -100 : summary.profitpercentage : 0)}% </Col>

                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="text-muted text-center p-2 fw-bold" style={{ fontSize: '1rem' }}>Profit</Card.Subtitle>

                                                                    </Card.Body>
                                                                </Card>

                                                            </Col>
                                                            <Col sm={12} md={6}>
                                                                <Card>
                                                                    <Card.Body className={`border border-${summary?.cash > 0 ? 'success' : 'danger'}`}>
                                                                        <Card.Title className="text-center h6 mb-0">
                                                                            <Row className={`${summary?.cash > 0 ? 'text-success' : 'text-danger'} p-2 `} style={{ fontSize: '1.2rem' }}>
                                                                                <Col sm={12} md={9} className={`text-center fw-bold ${hoverCash ? 'active' : ''} border-end`} title="client.received-(vendor.paid+expense.general+salary)" onMouseOver={() => { setHoverCash(true) }} onMouseLeave={() => { setHoverCash(false) }}> &#x20b9; {formatAccounting(summary ? summary.cash : 0)} </Col>
                                                                                <Col sm={12} md={3} className={`text-center fw-bold ${hoverCash ? 'active' : ''}`}> {formatAccounting(summary && !isNaN(summary.cashpercentage) ? summary.cashpercentage : 0)}% </Col>

                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="text-muted text-center p-2 fw-bold" style={{ fontSize: '1rem' }}>Clash Flow</Card.Subtitle>


                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                            {/* <Col sm={12} md={6}>
                                                                {
                                                                    profitpercentage ? <Line options={options2} data={profitpercentage} /> : null
                                                                }
                                                            </Col> */}
                                                        </Row>
                                                        <Row className="g-2">
                                                            <Col sm={12} md={6} lg={4}>

                                                                <Card className="border border-success">
                                                                    <Card.Body>
                                                                        <Card.Title className="h6" >
                                                                            <Row className={`${summary?.coutstanding > 0 ? 'text-success' : 'text-danger'} m-1`}>
                                                                                <Col className="text-center fw-bold">
                                                                                    <i className="fa fa-arrow-circle-down pe-4 text-success"></i>
                                                                                    &#x20b9;
                                                                                    {formatAccounting(summary ? summary.coutstanding : 0)}</Col>
                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7"> Client</Card.Subtitle>
                                                                        <Row className="summary ml-auto"><Col xs={4}> Basic</Col> <u className={`col ${hoverProfit ? 'active' : ''}`}>  &#x20b9; {formatAccounting(summary ? summary.cbasic : 0)}</u> </Row>
                                                                        <Row className="summary"><Col xs={4}> Tax</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.ctax : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Inclusive</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.cinclusive : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> TDS</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.ctds : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Received</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}> &#x20b9; {formatAccounting(summary ? summary.cpay : 0)}</Col> </Row>

                                                                    </Card.Body>
                                                                </Card>

                                                            </Col>
                                                            <Col sm={12} md={6} lg={4} >

                                                                <Card className="border border-danger">
                                                                    <Card.Body>

                                                                        <Card.Title className="h6">
                                                                            <Row className={`${summary?.voutstanding > 0 ? 'text-success' : 'text-danger'} m-1`}>
                                                                                <Col className="text-center fw-bold">
                                                                                    <i className="fa fa-arrow-circle-up pe-4 text-danger"></i>
                                                                                    &#x20b9;
                                                                                    {formatAccounting(summary ? summary.voutstanding : 0)}</Col>
                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7">Vendor</Card.Subtitle>

                                                                        <Row className="summary"><Col xs={4}> Basic</Col> <u className={`col ${hoverProfit ? 'active' : ''}`}>  &#x20b9; {formatAccounting(summary ? summary.vbasic : 0)}</u> </Row>
                                                                        <Row className="summary"><Col xs={4}> Tax</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.vtax : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Inclusive</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.vinclusive : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> TDS</Col> <Col > &#x20b9; {formatAccounting(summary ? summary.vtds : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Paid</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}> &#x20b9; {formatAccounting(summary ? summary.vpay : 0)}</Col> </Row>


                                                                    </Card.Body>
                                                                </Card>

                                                            </Col>
                                                            <Col sm={12} md={4} lg={4}>

                                                                <Card className="border border-primary">
                                                                    <Card.Body>

                                                                        <Card.Title className="h6">
                                                                            <Row className={`text-danger m-1`}>
                                                                                <u className={`text-center fw-bold col ${hoverProfit ? 'active' : ''}`}>
                                                                                    <i className="fa fa-arrow-circle-up pe-4 text-danger"></i>
                                                                                    &#x20b9;
                                                                                    {formatAccounting(summary ? (Number(summary.general ?? 0) + Number(summary.salary ?? 0) + Number(summary.tax ?? 0)) : 0)}</u>
                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7">Expense</Card.Subtitle>

                                                                        <Row className="summary"><Col xs={4}> General</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}>  &#x20b9; {formatAccounting(summary ? summary.general : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Salary</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}> &#x20b9; {formatAccounting(summary ? summary.salary : 0)}</Col> </Row>

                                                                        <Row className="summary"><Col xs={4}> GST</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}> &#x20b9; {formatAccounting(summary ? summary.tax : 0)}</Col> </Row>
                                                                        <Row className="summary"><Col xs={4}> Wallet</Col> <Col className={`${hoverCash ? 'uactive' : ''}`}> &#x20b9; {formatAccounting(summary ? summary.wallet : 0)}</Col> </Row>

                                                                    </Card.Body>
                                                                </Card>

                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2 mt-1 ">
                                                            <Col sm={12} md={4} >
                                                                <Card className="border border-warning">
                                                                    <Card.Body>
                                                                        <Card.Title className="h6">
                                                                            <Row className={`${summary?.Cash > 0 ? 'text-success' : 'text-danger'} m-1`}>
                                                                                <Col className="text-center fw-bold">

                                                                                    &#x20b9; {formatAccounting(Number(summary?.Cash ?? 0))}</Col>
                                                                            </Row>
                                                                        </Card.Title>

                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7">Cash</Card.Subtitle>
                                                                    </Card.Body>

                                                                </Card>
                                                            </Col>
                                                            <Col sm={12} md={4} >
                                                                <Card className="border border-warning">
                                                                    <Card.Body>
                                                                        <Card.Title className="h6">
                                                                            <Row className={`${summary?.Current > 0 ? 'text-success' : 'text-danger'} m-1`}>
                                                                                <Col className="text-center fw-bold">

                                                                                    &#x20b9; {formatAccounting(Number(summary?.Current ?? 0))}</Col>
                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7">Current</Card.Subtitle>
                                                                    </Card.Body>

                                                                </Card>
                                                            </Col>
                                                            <Col sm={12} md={4} >
                                                                <Card className="border border-warning">
                                                                    <Card.Body>
                                                                        <Card.Title className="h6">
                                                                            <Row className={`${summary?.Savings > 0 ? 'text-success' : 'text-danger'} m-1`}>
                                                                                <Col className="text-center fw-bold">

                                                                                    &#x20b9; {formatAccounting(Number(summary?.Savings ?? 0))}</Col>
                                                                            </Row>
                                                                        </Card.Title>
                                                                        <Card.Subtitle className="mb-1 text-muted text-center h7">Saving</Card.Subtitle>
                                                                    </Card.Body>

                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        : null
                                }
                                {highlightChartPermission ?
                                    <React.Fragment>
                                        <Col className={`p-0 col-dash pe-1  `} sm={12} >
                                            <Card className={`border  mt-2 ${showChart ? 'show' : ''}`} >
                                                <Card.Title className="p-2 text-muted d-flex mb-0 ">
                                                    <b><i className="fa fa-bar-chart-o text-primary pt-2 pe-2"></i>Spread </b>

                                                    <div className="ms-auto">

                                                        <i className={`btn btn-sm btn-outline-secondary me-1 fa ${showFullscreen ? 'fa-compress' : 'fa-expand'}`} role="button" onClick={() => setShowFullscreen(!showFullscreen)}></i>
                                                        <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showChart ? 'up' : 'down'}`} role="button" onClick={() => setShowChart(!showChart)}></i>
                                                    </div>
                                                </Card.Title>
                                                <Card.Body style={{ display: showChart ? 'block' : 'none' }}>

                                                    {
                                                        highlightChart ? <Bar options={options} data={highlightChart} />
                                                            : null
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col className={`p-0 col-dash pe-1  `} sm={12} >
                                            <Card className={`border  mt-2 ${showChartClosed ? 'show' : ''}`} >
                                                <Card.Title className="p-2 text-muted d-flex mb-0 ">
                                                    <b><i className="fa fa-bar-chart-o text-danger pt-2 pe-2"></i>Closed Projects </b>

                                                    <div className="ms-auto">

                                                        <i className={`btn btn-sm btn-outline-secondary me-1 fa ${showFullscreen ? 'fa-compress' : 'fa-expand'}`} role="button" onClick={() => setShowFullscreen(!showFullscreen)}></i>
                                                        <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showChartClosed ? 'up' : 'down'}`} role="button" onClick={() => setShowChartClosed(!showChartClosed)}></i>
                                                    </div>
                                                </Card.Title>
                                                <Card.Body style={{ display: showChartClosed ? 'block' : 'none' }}>

                                                    {
                                                        highlightChartClosed ? <Bar options={options} data={highlightChartClosed} />
                                                            : null
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </React.Fragment>
                                    : null
                                }


                            </Row>
                        </Col>
                        : null

                }
                {
                    pendingViewPermission || insightPermission || highlightPermission || dummyViewPermission ?
                        <Col className=" p-0 col-dash  order-xl-3 order-lg-2 order-2" xl={4} lg={4} md={12}>

                            

                            

                            {pendingViewPermission ? (
                                <React.Fragment>

                                    <Card className={`border  mt-2 ${showPending ? 'show' : ''}`}>
                                        <Card.Title className={`p-2 text-muted mb-0 d-flex `}>
                                            <b><i className=" fa fa-exclamation-circle pe-2 text-warning pt-2"></i> Pending Request </b> <Badge bg="success" className="ms-2 ">{request ? request.filter(e => e.table !== "Expense").length : 0}</Badge>
                                            <div className="ms-auto">
                                                <i className={` btn btn-sm btn-outline-secondary fa fa-chevron-${showPending ? 'up' : 'down'}`} role="button" onClick={() => setShowPending(!showPending)}></i>
                                            </div>
                                        </Card.Title>
                                        <Card.Body className="overflow-auto" style={{ display: showPending ? 'block' : 'none' }}>
                                            <PerfectScrollbar className="p-1">
                                                <Container fluid className="p-1" style={{ maxHeight: "80vh" }}>

                                                    {
                                                        request ? request.filter(e => e.table !== "Expense").map((e, i) => {
                                                            return <Container fluid className="mt-1 p-0 mb-1 border rounded position-relative shadow-sm" key={`ca-${i}`} >
                                                                {
                                                                    // pendDeletePermission ?
                                                                    //     <i className="fa fa-times-circle text-danger position-absolute top-0 start-0 close-but" role="button" onClick={() => {
                                                                    //         deleteRequest(e.id);
                                                                    //     }}></i> : null
                                                                }
                                                                <Row className="m-auto p-1 ">
                                                                    <Col className="p-0 ">
                                                                        <Button variant="link" size="sm" className="p-0" onClick={() => {

                                                                            setSelected({ ...e, title: e.table });

                                                                            setShowModal(true);
                                                                        }}   >{e.type}</Button>
                                                                    </Col>
                                                                    <Col className="border-start text-end p-0 tiles">
                                                                        <sub className="text-muted fs-7"> {e.sub && e.name ? e.sub : 'Req'}</sub>
                                                                        <b style={{ fontSize: '.825em' }}> {e.sub && e.name ? e.name : e.reqname}</b>
                                                                    </Col>
                                                                </Row>
                                                                {e.remarks && e.remarks !== "" ? <Row className="p-1 border-top ms-auto me-auto">
                                                                    <Col size={12} className="p-0 text-wrap">Remark: {e.remarks}</Col>
                                                                </Row> : null

                                                                }


                                                                <Row className="p-1 border-top ms-auto me-auto">
                                                                    <Col className="p-0">
                                                                        <span style={{ fontSize: '.75em' }}><i className="fa fa-inr"></i> {formatAccounting(e.amount)}</span>
                                                                        <span className="float-end text-muted fs-7 ps-2">{format(new Date(e.createdAt), "dd-MM-yyyy")}</span>
                                                                    </Col>

                                                                    <Col className=" p-0 border-start dash-btn text-end">

                                                                        <span className="float-start  fs-7 ps-2">
                                                                            <span className="text-muted">by:</span>
                                                                            <b style={{ fontSize: '1.1em' }}>{
                                                                                e.reqname
                                                                            }</b>
                                                                        </span>
                                                                        {
                                                                            pendingApprovePermission ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                        e.is_rejectable !== 'N' ? <Button variant="outline-danger" size="sm" className="me-2" onClick={() => {
                                                                                            rejectRequest(e.id)
                                                                                        }}><i className="fa fa-close"></i></Button> : null
                                                                                    }

                                                                                    {
                                                                                        e.is_approval !== 'N' ? <Button variant="outline-success" size="sm" onClick={() => {
                                                                                            approveRequest(e.id)
                                                                                        }}><i className="fa fa-check"></i></Button> :
                                                                                            <Button variant="outline-primary" size="sm" onClick={() => {
                                                                                                setShowModal(true);
                                                                                                setSelected(e);
                                                                                            }}>{e.additional_status}</Button>
                                                                                    }</React.Fragment> : <b className="float-end ps-2">{e.status === 'P' ? `${e.additional_status ?? ""}-Pending` : e.status === 'A' ? 'Approved' : "Rejected"}</b>
                                                                        }


                                                                    </Col>
                                                                </Row>

                                                            </Container>
                                                        }) : null
                                                    }



                                                </Container>
                                            </PerfectScrollbar>

                                        </Card.Body>
                                    </Card>

                                    <Card className={`border  mt-2 ${showPendingExpense ? 'show' : ''}`}>
                                        <Card.Title className={`p-2 text-muted mb-0 d-flex `}>
                                            <b><i className=" fa fa-exclamation-circle pe-2 text-warning pt-2"></i> Pending Expense </b> <Badge bg="success" className="ms-2 ">{request ? request.filter(e => e.table === "Expense").length : 0}</Badge>
                                            <div className="ms-auto">
                                                <i className={` btn btn-sm btn-outline-secondary fa fa-chevron-${showPendingExpense ? 'up' : 'down'}`} role="button" onClick={() => setShowPendingExpense(!showPendingExpense)}></i>
                                            </div>
                                        </Card.Title>
                                        <Card.Body className="overflow-auto" style={{ display: showPendingExpense ? 'block' : 'none' }}>
                                            <PerfectScrollbar className="p-1">
                                                <Container fluid className="p-1" style={{ maxHeight: "80vh" }}>

                                                    {
                                                        request ? request.filter(e => e.table === "Expense").map((e, i) => {
                                                            return <Container fluid className="mt-1 p-0 mb-1 border rounded position-relative shadow-sm" key={`ca-${i}`} >
                                                                {
                                                                    // pendDeletePermission ?
                                                                    //     <i className="fa fa-times-circle text-danger position-absolute top-0 start-0 close-but" role="button" onClick={() => {
                                                                    //         deleteRequest(e.id);
                                                                    //     }}></i> : null
                                                                }
                                                                <Row className="m-auto p-1 ">
                                                                    <Col className="p-0 ">
                                                                        <Button variant="link" size="sm" className="p-0" onClick={() => {

                                                                            setSelected({ ...e, title: e.table });

                                                                            setShowModal(true);
                                                                        }}   >{e.type}</Button>
                                                                    </Col>
                                                                    <Col className="border-start text-end p-0 tiles">
                                                                        <sub className="text-muted fs-7"> {e.sub && e.name ? e.sub : 'Req'}</sub>
                                                                        <b style={{ fontSize: '.825em' }}> {e.sub && e.name ? e.name : e.reqname}</b>
                                                                    </Col>
                                                                </Row>
                                                                {e.remarks && e.remarks !== "" ? <Row className="p-1 border-top ms-auto me-auto">
                                                                    <Col size={12} className="p-0 text-wrap">Remark: {e.remarks}</Col>
                                                                </Row> : null

                                                                }


                                                                <Row className="p-1 border-top ms-auto me-auto">
                                                                    <Col className="p-0">
                                                                        <span style={{ fontSize: '.75em' }}><i className="fa fa-inr"></i> {formatAccounting(e.amount)}</span>
                                                                        <span className="float-end text-muted fs-7 ps-2">{format(new Date(e.createdAt), "dd-MM-yyyy")}</span>
                                                                    </Col>

                                                                    <Col className=" p-0 border-start dash-btn text-end">

                                                                        <span className="float-start  fs-7 ps-2">
                                                                            <span className="text-muted">by:</span>
                                                                            <b style={{ fontSize: '1.1em' }}>{
                                                                                e.reqname
                                                                            }</b>
                                                                        </span>
                                                                        {
                                                                            pendingApprovePermission ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                        e.is_rejectable !== 'N' ? <Button variant="outline-danger" size="sm" className="me-2" onClick={() => {
                                                                                            rejectRequest(e.id)
                                                                                        }}><i className="fa fa-close"></i></Button> : null
                                                                                    }

                                                                                    {
                                                                                        e.is_approval !== 'N' ? <Button variant="outline-success" size="sm" onClick={() => {
                                                                                            approveRequest(e.id)
                                                                                        }}><i className="fa fa-check"></i></Button> :
                                                                                            <Button variant="outline-primary" size="sm" onClick={() => {
                                                                                                setShowModal(true);
                                                                                                setSelected(e);
                                                                                            }}>{e.additional_status}</Button>
                                                                                    }</React.Fragment> : <b className="float-end ps-2">{e.status === 'P' ? `${e.additional_status ?? ""}-Pending` : e.status === 'A' ? 'Approved' : "Rejected"}</b>
                                                                        }


                                                                    </Col>
                                                                </Row>

                                                            </Container>
                                                        }) : null
                                                    }



                                                </Container>
                                            </PerfectScrollbar>

                                        </Card.Body>
                                    </Card>
                                </React.Fragment>

                            ) : null
                            }

                            {dummyViewPermission ? (
                                <Card className={`border  mt-2 ${showDummy ? 'show' : ''}`}>
                                    <Card.Title className={`p-2 text-muted mb-0 d-flex `}>
                                        <b><i className=" fa fa-exclamation-circle pe-2 text-warning pt-2"></i> Dummy / Auto </b>
                                        <Badge bg="success" className="ms-2 ">{request ? request.length : 0}</Badge>
                                        <div className="ms-auto">
                                            <i className={` btn btn-sm btn-outline-secondary fa fa-chevron-${showDummy ? 'up' : 'down'}`} role="button" onClick={() => setShowDummy(!showDummy)}></i>
                                        </div>
                                    </Card.Title>
                                    <Card.Body className="overflow-auto" style={{ display: showDummy ? 'block' : 'none' }}>
                                        <PerfectScrollbar className="p-1">
                                            <Container fluid className="p-1" style={{ maxHeight: "80vh" }}>

                                                {
                                                    request ? request.map((e, i) => {
                                                        return <Container fluid className="mt-1 p-0 mb-1 border rounded position-relative shadow-sm" key={`ca-${i}`} >

                                                            <Row className="m-auto p-1 ">
                                                                <Col className="p-0 ">
                                                                    <Button variant="link" size="sm" className="p-0" onClick={() => {

                                                                        setSelected({ ...e, title: e.table });

                                                                        setShowModal(true);
                                                                    }}   >{e.type}</Button>
                                                                </Col>
                                                                <Col className="border-start text-end p-0 tiles">
                                                                    <sub className="text-muted fs-7"> {e.sub && e.name ? e.sub : 'Req'}</sub>
                                                                    <b style={{ fontSize: '.825em' }}> {e.sub && e.name ? e.name : e.reqname}</b>
                                                                </Col>
                                                            </Row>
                                                            {e.remarks && e.remarks !== "" ? <Row className="p-1 border-top ms-auto me-auto">
                                                                <Col size={12} className="p-0 text-wrap">Remark: {e.remarks}</Col>
                                                            </Row> : null

                                                            }


                                                            <Row className="p-1 border-top ms-auto me-auto">
                                                                <Col className="p-0">
                                                                    <span style={{ fontSize: '.75em' }}><i className="fa fa-inr"></i> {formatAccounting(e.amount)}</span>
                                                                    <span className="float-end text-muted fs-7 ps-2">{format(new Date(e.createdAt), "dd-MM-yyyy")}</span>
                                                                </Col>

                                                                <Col className=" p-0 border-start dash-btn text-end">

                                                                    <span className="float-start  fs-7 ps-2">
                                                                        <span className="text-muted">by:</span>
                                                                        <b style={{ fontSize: '1.1em' }}>{
                                                                            e.reqname
                                                                        }</b>
                                                                    </span>



                                                                </Col>
                                                            </Row>

                                                        </Container>
                                                    }) : null
                                                }



                                            </Container>
                                        </PerfectScrollbar>

                                    </Card.Body>
                                </Card>
                            ) : null
                            }

                            {!showFullscreen && false ?
                                <Col className="p-0 col-dash pe-1 order-2" sm={12}>
                                    <Card className={`border  mt-2 ${showAction ? 'show' : ''}`} >
                                        <Card.Title className="p-2 text-muted d-flex mb-0 ">
                                            <b><i className="fa fa-asterisk pe-2 text-danger pt-2" ></i>Action Required </b><Badge bg="success" className="ms-2 ">{0}</Badge>

                                            <div className="ms-auto">
                                                <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showAction ? 'up' : 'down'}`} role="button" onClick={() => setAction(!showAction)}></i>
                                            </div>
                                        </Card.Title>
                                        <Card.Body  >

                                        </Card.Body>
                                    </Card>
                                </Col> : null
                            }
                            {
                                highlightPermission && costcenter === "0" && !showFullscreen ?

                                    <Card className={`border   mt-2 ${showHigh ? 'show' : ''}`} >
                                        <Card.Title className="p-2 text-muted d-flex mb-0 ">
                                            <b><i className="fa fa-history pe-2 text-primary pt-2"></i> Today's Happening </b> <Badge bg="success" className="ms-2 ">{hightlight ? hightlight.length : 0}</Badge>
                                            <div className="ms-auto">
                                                <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showHigh ? 'up' : 'down'}`} role="button" onClick={() => setShowHigh(!showHigh)}></i>
                                            </div>
                                        </Card.Title>
                                        <Card.Body className="overflow-auto" style={{ display: showHigh ? 'block' : 'none' }}>


                                            <Container fluid className="p-1">


                                                {
                                                    hightlight.map((e, i) => {
                                                        return <Button size="sm" variant="outline-success" key={`hi_${i}`} className="me-2 mt-2">
                                                            {e.name}{" "}
                                                            <Badge bg="success" >{e.ACTIVE}</Badge>
                                                        </Button>

                                                    })
                                                }

                                            </Container>
                                        </Card.Body>
                                    </Card> : null
                            }
                            {
                                insightPermission && !showFullscreen ?

                                    <Card className={`border  mt-2 ${showInsight ? 'show' : ''}`}>
                                        <Card.Title className="p-2 text-muted d-flex mb-0 ">
                                            <b><i className="fa fa-line-chart pe-2 text-primary pt-2"></i>Insight's </b> <Badge bg="success" className="ms-2 ">{insight ? insight.length : 0}</Badge>
                                            <div className="ms-auto">
                                                <i className={`btn btn-sm btn-outline-secondary fa fa-chevron-${showInsight ? 'up' : 'down'}`} role="button" onClick={() => setShowInsight(!showInsight)}></i>
                                            </div>
                                        </Card.Title>
                                        <Card.Body className="overflow-auto" style={{ display: showInsight ? 'block' : 'none' }}>

                                            <Container fluid className="p-1">

                                                {
                                                    insight.map((e, i) => {

                                                        return <Button size="sm" variant="outline-success" key={`ins_${i}`} className="me-2 mt-2">
                                                            {e.name} {" "}
                                                            <Badge bg="success" >{e.ACTIVE} | {e.closed}</Badge>
                                                        </Button>

                                                    })
                                                }
                                            </Container>

                                        </Card.Body>
                                    </Card> : null
                            }
                        </Col> : null
                }



            </Row>
            <Row className="m-auto d-flex">

            </Row>


            <Modal show={showModal} onHide={() => { handleModalClose() }} dialogClassName="modal-90w">
                {/* <Modal.Header closeButton >
                    <Modal.Title>{selected && selected.title ? selected.title : "Payment Authorization"}</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="p-0">
                    <div className="d-block d-md-none">
                        <CustomLoader />
                    </div>
                    {
                        selected?.table === 'Payment' ?

                            <Payment permitted={permitted}
                                mode="Process" dseleted={selected ? selected.pk_id : null}
                                dback={handleModalClose}></Payment> :

                            selected?.table === 'Invoice' ?
                                <Invoice permitted={permitted}
                                    entity={selected.type.includes('Client') ? 'Client' : 'Vendor'}
                                    dShow={true} dShowId={selected.pk_id}
                                    dback={handleModalClose}></Invoice> :

                                selected?.table === 'Expense' ? <Expense permitted={permitted} dShow={true} dShowId={selected.pk_id} dback={handleModalClose}></Expense> : null
                    }

                </Modal.Body>

            </Modal>

            

            {/* <div>

                {location ? (
                    <div>
                        <p>Location: {JSON.stringify(location)}</p>
                    </div>
                ) : null}
            </div> */}



        </Container>

    );








}