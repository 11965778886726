import { CHANGEATTENDANCEREQ, CHANGEATTENDANCEUSER, LOADALLATTENDANCE, LOADALLATTENDANCEREQ, LOADATTENDANCEREQ, LOADATTENDANCEUSER, RESETATTENDANCEREQ, SET_NOTIFICATION } from "../constant/constant";
import { pAxios } from "..";


function loadAllAttendanceReqService(status) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/attendanceReq?status=${status}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLATTENDANCEREQ, attendanceRequests: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load attendance data", variant: "danger", err } })
            });
    });

}

export function loadAttendanceReqService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/attendanceReq/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADATTENDANCEREQ, attendanceReq: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load attendance data", variant: "danger", err } })
            });
    });

}

export function saveAttendanceReqService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/attendanceReq`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                //   store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Attendance entry saved`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETCREDIT});
            }
        })
            .catch(err => {

                reject({ message: "Failed to save attendance data", variant: "danger", err })
            });
    });

}

export function approveService(data){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/attendance/approve`,
            "data": JSON.stringify(data),
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                 
            // store.dispatch({
            //   type: SET_NOTIFICATION, notification:
                  resolve({ message: `Attendance approved successfully`, variant: "success", err: "" });
            //   });
           }
        })
            .catch(err => {
                
                reject({message:"Failed to approved attendance",variant:"danger",err})
            });
    });
  
  }

export const loadAttendaceReq = (id) => {

    return dispatch => {

        loadAttendanceReqService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}

export const loadAllAttendaceReq = (status) => {

    return dispatch => {

        loadAllAttendanceReqService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}




export const changeAttendanceReq = ({ name, value }) => {

    return dispatch => {
        dispatch({ type: CHANGEATTENDANCEREQ, name, value });
    }

}

export const resetAttendanceReq = () => {

    return dispatch => {
        dispatch({ type: RESETATTENDANCEREQ, });
    }

}



