
import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, addDays } from 'date-fns';
import { Button, Card, Col, Container, Row, Table as BTable, FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Table from "../Table";
import DatePicker from 'react-date-picker';
import startOfDay from 'date-fns/startOfDay';
import { loadAllAttendace } from '../../action/attendanceAction';
import { MontlyCalendar } from './MonthlyCalendar';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function CalendarView({ minDate, permitted, header }) {

    const dispatch = useDispatch();

    const userTypeRef = React.useRef(null);
    const [createPermission, setCreatePermission] = React.useState(true);
    const [updatePermission, setUpdatePermission] = React.useState(true);

    const [showAttendanceModal, setShowAttendanceModal] = React.useState(false);
    const [selectedAttendance, setSelectedAttendance] = React.useState(null);

    const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), "yyyy-MM-dd"));
    const [selectedUser, setSelectedUser] = useState(null);

    const [ownPermission, setOwnPermission] = React.useState(permitted("ATTENDANCE_OWN"));

    const users = useSelector(
        (state) => state.lookup.user
    );

    const attendances = useSelector(
        (state) => state.attendance.attendances
    );

    const attendanceRequest = useSelector(
        (state) => state.attendanceReq.attendanceReq
    );


    const theme = useSelector((state) => state.ThemeOptions);


    React.useEffect(() => {
        dispatch(loadAllAttendace(startDate, endDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate]);

    const load = () => {

        if (selectedUser) {
            dispatch(loadAllAttendace(startDate, endDate, selectedUser));
        }
    }

    return (
        < React.Fragment >

            <Container fluid>

                <Card>
                    <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                        <h6 className="mb-0">Calendar</h6>
                    </Card.Header>
                    <Card.Body>
                        {
                            ownPermission ? null :
                            <React.Fragment>

                                <Row className="mb-3 g-2">
                                    <Col sm={12} md={3}>

                                        <Typeahead
                                            id="floating-user-lbl"
                                            onChange={(e) => {
                                                setSelectedUser(e[0]?.id)
                                            }}
                                            onBlur={() => {
                                                if (!selectedUser) {
                                                    userTypeRef.current?.clear();

                                                }
                                            }}
                                            clearButton
                                            labelKey="name"
                                            options={users ?? []}
                                            ref={userTypeRef}
                                            placeholder="Select a user"
                                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                                return (

                                                    <FloatingLabel controlId="floatingUser" label="Select a user">
                                                        <Form.Control
                                                            {...inputProps}
                                                            ref={(node) => {
                                                                inputRef(node);
                                                                referenceElementRef(node);
                                                            }}
                                                        />
                                                    </FloatingLabel>

                                                );
                                            }}
                                            selected={selectedUser ? users.filter(e => e.id === Number(selectedUser)) : []}
                                        />

                                    </Col>
                                    <Col sm={12} md={1} className="ms-auto">
                                        <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end align-items-end h-100  ">


                                            <Button variant="primary" size="sm" onClick={() => { load() }}>Search</Button>


                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                            </React.Fragment>
                        }
                        <Row>

                            <Col md={6} sm={12}>
                                <BTable striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>
                                                Desc
                                            </th>
                                            <th className='text-center'>
                                                Bal
                                            </th>
                                            <th className='text-center'>
                                                Used
                                            </th>
                                            <th className='text-center'>
                                                Month
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Casual Leave</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-end'>0</td>
                                        </tr>
                                        <tr>
                                            <td>Sick Leave</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-end'>0</td>
                                        </tr>
                                        <tr>
                                            <td>LOP</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-end'>0</td>
                                        </tr>
                                        <tr>
                                            <td>Sundays</td>
                                            <td className='text-end'>0</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-end'>0</td>
                                        </tr>

                                    </tbody>
                                </BTable>
                            </Col>
                            <Col md={6} sm={12}>
                                <MontlyCalendar attendanceData={attendances ?? {}} header={header} setStartDate={setStartDate} setEndDate={setEndDate} from={attendanceRequest?.from} to={attendanceRequest?.to} mode={attendanceRequest?.mode} ></MontlyCalendar>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </Container>


        </React.Fragment>
    );

}





