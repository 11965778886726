import React, { useState } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, isAfter } from 'date-fns';
import { Button } from 'react-bootstrap';
import {  useSelector } from 'react-redux';

import startOfDay from 'date-fns/startOfDay';

export function MontlyCalendar({ attendanceData, header, setStartDate, setEndDate, from, to, mode }) {

    const [currentMonth, setCurrentMonth] = useState(new Date());

    const theme = useSelector((state) => state.ThemeOptions);

  

    const nextMonth = () => {
        const nxtMonth = addMonths(currentMonth, 1)
        setCurrentMonth(nxtMonth);
        setStartDate(format(startOfMonth(nxtMonth), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(nxtMonth), "yyyy-MM-dd"));
    };

    const prevMonth = () => {
        const prevMonth = (subMonths(currentMonth, 1));

        setCurrentMonth(prevMonth);
        setStartDate(format(startOfMonth(prevMonth), "yyyy-MM-dd"));
        setEndDate(format(endOfMonth(prevMonth), "yyyy-MM-dd"));
    };

    const renderHeader = () => (
        <div className={`cal-header ${theme.headerBackgroundColor} text-light `}>
            <Button onClick={prevMonth} variant='light'>Prev</Button>
            <h5>{format(currentMonth, 'MMMM yyyy')}</h5>
            <Button onClick={nextMonth} variant='light'>Next</Button>
        </div>
    );

    const renderDays = () => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return (
            <div className="cal-days">
                {days.map(day => (
                    <div key={day} className="day-name">
                        {day}
                    </div>
                ))}
            </div>
        );
    };

    const getClassName = (status, day, mode) => {

        if (isSameDay(day, new Date())) {
            return 'today';
        }

        else if (!status && new Date(day).getDay() !== 0) {
            return "absent";
        }
        else if (!status && new Date(day).getDay() === 0) {
            return "";
        }
        else if (status === "L") {
            return "leave";
        }
        else if (status === 'N') {
            return "absent";
        }
        else if (status === 'Y' && mode !== 'WO') {
            return "present";
        }
        else if (status === 'H') {
            return "holiday";
        }
    }

    const getDayDesc = (attendance, day) => {

        if (!attendance && new Date(day).getDay() !== 0) {
            return "AB"
        }

        else if (!attendance && new Date(day).getDay() === 0) {
            return "";
        }

        else if (attendance.status === 'Y') {
            return attendance.mode !== "WO" ? attendance.mode : "";
        }

        else if (attendance.status === 'N') {
            return "AB"
        }

        else if (attendance.status === 'L') {
            return attendance.leaveType;
        }

        else if (attendance.status === 'H') {
            return attendance.holiday ?? "Holiday";
        }
    }



    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let days = [];
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const dayFormatted = format(day, 'yyyy-MM-dd');
                const attendance = attendanceData[dayFormatted];
                const clsname = isSameMonth(day, monthStart) ? getClassName(attendance?.status, day, attendance?.mode) : '';
                const attClass = !isSameMonth(day, monthStart) ? 'disabled' : null;
                const selRange = day >= startOfDay(new Date(from)) && day <= startOfDay(new Date(to)) ? "selected" : "";

                days.push(
                    <div role={!attClass ? `button` : 'read-only'}
                        key={day} className={`cal-cell ${header ? 'cal-height' : ''} ${attClass ?? ''} ${isAfter(day, new Date()) ? "" : clsname ?? ""} ${selRange}`}>

                        <span className='bg'>{format(day, 'd')}</span>

                        {isAfter(day, new Date()) ? "" :
                            <span className='mt-auto fw-bold day-des'>
                                {selRange ? mode : getDayDesc(attendance, day)}
                            </span>}


                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="cal-row" key={day}>
                    {days}

                </div>
            );
            days = [];
        }

        return <div className="cal-body">{rows}</div>;
    };



    return (
        <div className="cal-container ">
            {header ? renderHeader() : ''}

            {renderDays()}
            {renderCells()}
        </div>
    );
};