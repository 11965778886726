import {
    LOADALLLEAVE,
    CHANGELEAVE,
    initialState,
    LOADLEAVE,
    RESETLEAVE,
} from "../constant/constant";

export default function reducer(state = { ...initialState.leave }, action) {

    switch (action.type) {
        case LOADALLLEAVE:
            return { ...state, leaves: [ ...action.leaves ] }

        case LOADLEAVE:
            return { ...state, leave: { ...action.leave } }

        case CHANGELEAVE:
            return { ...state, leave: { ...state.leave, [action.name]: action.value } };

        case RESETLEAVE:
            return { ...state, leave: { ...initialState.leave.leave } };
        default:
            return { ...state };
    }
}