import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker'
import { Container, Form, Card, Row, Col, FloatingLabel, Button, InputGroup, Badge } from "react-bootstrap";
import MasterToolBar from "./MasterToolBar";
import Table from "./Table";
import { isObjectEmpty } from "../util/util"
import { states } from '../constant/in_state'

import { loadAllUser, loadUser, changeUser, resetUser, saveUserService, loadUserLookup, resetPassword } from "../action/userAction";
import { notification } from "../action/notificationAction";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  setEnableMobileMenu
} from '../constant/constant';
import { numberCheck } from "../constant/numberCheck";
import { format } from "date-fns";
import { formatCurrency } from "../constant/format";

export default function User({ permitted }) {


  const dispatch = useDispatch();
  const typeRef = React.useRef(null);
  const categoryRef = React.useRef(null);

  const users = useSelector(
    (state) => state.user.users
  );

  const user = useSelector(
    (state) => state.user.user
  );

  const categories = useSelector(
    (state) => state.lookup.category
  );

  // eslint-disable-next-line no-unused-vars
  const [selectedUserId, setSelectedUserId] = useState();


  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('active');


  const handleShow = (seletedUser) => {

    setErrors({});
    if (seletedUser != null) {

      setShow(true);
      setSelectedUserId(seletedUser.original.id);
      dispatch(loadUser(seletedUser.original.id));
    }
    else {
      dispatch(resetUser());
      setSelectedUserId(null);
      setShow(true);
    }

  }

  const handleClose = () => {
    setErrors({});
    dispatch(loadAllUser(status));
    setSelectedUserId(null);
    setShow(false);
  }


  React.useEffect(() => {
    dispatch(loadAllUser(status));

  }, [dispatch, status]);


  React.useEffect(() => {

    dispatch(setEnableMobileMenu(false));

  }, [dispatch]);






  const handleSubmit = e => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let dataToPost = {};
      let method = "POST";

      if (!user.id) {
        dataToPost = {
          ...user, status: 'Y',
          address: isObjectEmpty(user.address) ? null : user.address,
          aadhar: isObjectEmpty(user.aadhar) ? null : user.aadhar,
          bank: isObjectEmpty(user.bank) ? null : user.bank,
          client_category: user.client_category ? user.client_category.join(",") : null,
          salary_proportion: user.salary_proportion ? JSON.stringify(user.salary_proportion) : null
        };
      }
      else {
        dataToPost = {
          ...user,
          address: isObjectEmpty(user.address) ? null : user.address,
          aadhar: isObjectEmpty(user.aadhar) ? null : user.aadhar,
          bank: isObjectEmpty(user.bank) ? null : user.bank,
          client_category: user.client_category ? user.client_category.join(",") : null,
          salary_proportion: user.salary_proportion ? JSON.stringify(user.salary_proportion) : null

        };
        method = "PATCH";
      }

      saveUserService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetUser());
        dispatch(loadUserLookup());
      })
        .catch(err => {
          dispatch(notification(err));
        })

    }

  }

  const setField = (field, value, model) => {
    dispatch(changeUser({ name: field, value, model }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const findFormErrors = () => {

    // eslint-disable-next-line no-unused-vars
    const { id, name, username, password, email, role, client_category, phone, aadhar, address1, city, state, pincode, bankname, ifsc, branch, accountnumber } = user
    const newErrors = {}

    if (!name || name === '') newErrors.name = 'Name cannot be blank!'

    if (!username || username === '') newErrors.username = 'User Name cannot be blank!'

    if (!id && (!password || password === '')) newErrors.password = 'Password cannot be blank!'

    if (email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
      newErrors.mail = 'Invalid email address'
    }


    if (!role || role === "0") newErrors.role = 'Select a role!'

    if (!user.client_category || user.client_category.length === 0) newErrors.client_category = 'User client category should not be empty'

    if (user.salary_proportion) {

      let sum = user.salary_proportion.reduce((a, b) => a + Number(b.proportion), 0);

      if (sum !== 100) newErrors.salary_proportion = 'Sum of proportion should be 100'

      // user.salary_proportion.forEach(element => {

      //   if (element.proportion || element.proportion === 0) newErrors.salary_proportion = 'Proportion cannot be blank!'

      // });

    }




    //if (!phone || phone === '') newErrors.phone = 'Phone number cannot be blank!'

    //if (!aadhar || aadhar === '') newErrors.aadhar = 'Aadhar number cannot be blank!'

    // if (!address1 || address1 === '') newErrors.address1 = 'Address cannot be blank!'

    // if (!city || city === '') newErrors.city = 'City cannot be blank!'

    // if (!pincode || pincode === '') newErrors.pincode = 'Pincode cannot be blank!'

    // if (!state || state === "0") newErrors.state = 'Select a State!'

    // if (!bankname || bankname === '') newErrors.bankname = 'Bankname cannot be blank!'

    // if (!accountnumber || accountnumber === '') newErrors.accountnumber = 'Account number cannot be blank!'

    // if (!ifsc || ifsc === '') newErrors.ifsc = 'Ifsc cannot be blank!'

    // if (!branch || branch === '') newErrors.branch = 'Branch cannot be blank!'

    return newErrors
  }

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(permitted(`USER_CREATE`));
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(permitted(`USER_UPDATE`));

  const theme = useSelector((state) => state.ThemeOptions);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        textFilter: true

      },
      {
        Header: 'UserName',
        accessor: 'username',
        textFilter: true
      },
      // {
      //   Header: 'Aadhar',
      //   accessor: 'aadhar.number',
      // },
      {
        Header: 'Phone',
        accessor: 'phone',
        textFilter: true
      },
      // {
      //   Header: 'Address',
      //   accessor: 'address.address1',
      //   className: 'text-truncate w-500'
      // },
      {
        Header: 'Cost Center',
        accessor: (cell) => {
          return cell.salary_proportion ? cell.salary_proportion.map(e => <Badge className="me-1"> {e.name} </Badge>) : ""
        },
        className: 'text-wrap w-300',
        hideFilter: true
      },
      {
        Header: 'Role',
        accessor: 'role',
      }, {
        Header: 'Wallet',
        accessor:(cell)=>formatCurrency(cell['wallet']),
        className: 'text-end',
        hideFilter: true
      },
      {
        Header: 'Closed',
        accessor: 'closedDate',
        show: status === 'active'
      },
      {
        Header: 'Action',
        hideFilter: true,
        accessor: (cell) => {
          return (

            <div className="text-center">
              {
                updatePermission ?
                  <Button size="sm" variant="outline-danger" className="pt-0 pb-0 me-2"
                    title="Reset password to default"
                    onClick={() => { dispatch(resetPassword(cell.id)) }}> <i className="fa fa-undo" role="button"></i></Button>
                  : null
              }
              {
                <i className={`fa fa-send ${cell.chat_id ? 'text-success' : 'text-danger'}`} title={`${cell.chat_id ? 'Telegram account activated' : 'Telegram account not activated'}`} role="button"></i>
              }

            </div>)
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, updatePermission]
  )


  return (
    <React.Fragment>

      <MasterToolBar createPermission={createPermission} updatePermission={updatePermission} id={user.id} show={show} handleShow={handleShow} handleSubmit={handleSubmit} handleClose={handleClose} ></MasterToolBar>

      {
        !show ? (

          <Container fluid >
            <Table columns={columns} data={users} title="User" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : []}> </Table>
          </Container>) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}><i className="fa fa-user-o  pe-1"> </i> User </Card.Header>
              <Card.Body className="p-0">
                <Form>
                  <Row className="m-auto">

                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title><i className="fa fa-user-o "></i> User Info</Card.Title>
                        <hr></hr>

                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingName" label="Name">
                              <Form.Control type="text" placeholder="Name" isInvalid={!!errors.name} value={user.name ?? ""} onChange={e => setField('name', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingUserName" label="User Name">
                              <Form.Control type="text" placeholder="User Name" isInvalid={!!errors.username} value={user.username ?? ""} onChange={e => setField('username', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.username} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        {
                          user.id ? null :
                            <Row className="mb-3" >

                              <Col>
                                <FloatingLabel controlId="floatingPwd" label="Password">
                                  <Form.Control type="password" placeholder="Password" isInvalid={!!errors.password} value={user.password ?? ""} onChange={e => setField('password', e.target.value)} />
                                  <Form.Control.Feedback type='invalid'>{errors.password} </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                        }


                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingEmail" label="Email">
                              <Form.Control type="email" placeholder="Email" isInvalid={!!errors.mail} value={user.email ?? ""} onChange={e => setField('email', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.mail} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingPhone" label="Phone Number">
                              <Form.Control type="text" placeholder="Phone" isInvalid={!!errors.phone} value={user.phone ?? ""} onChange={e => setField('phone', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingAPhone" label="Alternate Phone Number">
                              <Form.Control type="text" placeholder="Alternate Phone" isInvalid={!!errors.aphone} value={user.alternate_phone ?? ""} onChange={e => setField('alternate_phone', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.aphone} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingRole" label="Role">
                              <Form.Select id="floatingRole" aria-label="Role" isInvalid={!!errors.role} value={user.role ?? "0"} onChange={e => setField('role', e.target.value)}>
                                <option value="0">Select Role</option>
                                <option value="MASTER">MASTER</option>
                                <option value="DIRECTOR">DIRECTOR</option>
                                <option value="ACCOUNTS">ACCOUNTS</option>
                                <option value="SITEENGINEER">SITE ENGINEER</option>
                                <option value="SUPERVISOR">SUPERVISOR</option>
                                <option value="FINANCE">FINANCE</option>
                                <option value="PROJECTMANAGER">PROJECTMANAGER</option>
                              </Form.Select>
                              <Form.Control.Feedback type='invalid'>{errors.role} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col sm={12} >

                            <div style={{ position: "relative" }} className="dp">
                              <DatePicker maxDate={new Date()}
                                
                                id="dob"
                                value={user.dob ? new Date(user.dob) : null}
                                onChange={(date) => setField("dob", format(date, "yyyy-MM-dd"))}

                                className={`form-control ${!!errors.date ? "is-invalid" : ""}`}

                                format="dd-MM-yyyy"
                                  calendarIcon={<i className="fa fa-calendar"></i>}
                                  clearIcon = {null}
                              ></DatePicker>
                              <label className="black-text" htmlFor="dob">Date of birth</label>
                            </div>

                          </Col>
                        </Row>


                        <Row className="mb-3">

                          <Col>
                            <FloatingLabel controlId="floatingLsp" label="Last Salary Processed">
                              <Form.Control type="text" placeholder="Last Salary Processed" value={user.last_salaried ? format(new Date(user.last_salaried), 'dd-MM-yyyy') : ""} readOnly />

                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row >

                          <Col>
                            <FloatingLabel controlId="floatingWallet" label="Wallet">
                              <Form.Control type="text" placeholder="Wallet" value={user.wallet ?? ""} readOnly />

                            </FloatingLabel>
                          </Col>
                        </Row>



                      </Card.Body>
                    </Card>
                    <Col className="p-0 col-md-6 col-sm-12 col-xl-4">
                      <Card>
                        <Card.Body>
                          <Card.Title> <i className="fa fa-cash "></i> Salary </Card.Title>
                          <hr></hr>
                          <Row className="mb-3">

                            <Col>
                              <FloatingLabel controlId="floatingBaseSalary" label="Base Salary">
                                <Form.Control onKeyPress={numberCheck} type="text" placeholder="Base Salary" isInvalid={!!errors.base_salary} value={user.base_salary ?? ""} onChange={e => setField('base_salary', e.target.value)} />
                                <Form.Control.Feedback type='invalid'>{errors.base_salary} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>

                              <Typeahead
                                id="floating-label-user"
                                onChange={(e) => {
                                  setField('client_category', e.map(ele => ele.id))
                                  setField('salary_proportion', e.map(ele => {

                                    const prop = user?.salary_proportion?.find(i => i.id);

                                    return {
                                      categoryid: ele.id,
                                      name: ele.name,
                                      proportion: (prop?.proportion) ? prop.proportioN : 0
                                    }
                                  }))

                                }}
                                onBlur={() => {
                                  if (!user.client_category) {
                                    categoryRef.current?.clear();

                                  }
                                }}
                                clearButton
                                labelKey="name"
                                options={categories?.CLIENT ? categories?.CLIENT : []}
                                ref={categoryRef}
                                placeholder="Select Cost Center"

                                multiple
                                selected={categories?.CLIENT?.filter(e => user.client_category?.includes(e.id))}
                              />
                              {
                                errors.client_category ?
                                  <Form.Text className="text-danger">
                                    <i className="fa fa-exclamation"></i> {errors.client_category}
                                  </Form.Text> : null
                              }

                            </Col>
                          </Row>
                          <Row className="bg-secondary">

                            {
                              user.salary_proportion ? user.salary_proportion.map((e, i) => {
                                return <Col sm="12" key={`prop-${i}`}>
                                  <InputGroup className={`${i === 0 ? 'mt-2' : ''} mb-2`} >
                                    <InputGroup.Text id={`text-pecentage-${i}`}> {e.name}</InputGroup.Text>
                                    <Form.Control

                                      max="100"
                                      value={e.proportion}
                                      onChange={(ele) => {
                                        setField('salary_proportion', user?.salary_proportion?.map(i => {
                                          if (i.categoryid === e.categoryid) {
                                            return {
                                              ...i,
                                              proportion: ele.target.value
                                            }
                                          }
                                          return i;

                                        })
                                        );
                                      }
                                      }
                                      type="number"
                                      placeholder="Proportion"
                                      aria-describedby={`text-pecentage-${i}`}
                                      isInvalid={!!errors.salary_proportion}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.salary_proportion} </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>
                              }) : null
                            }
                          </Row>
                        </Card.Body>
                      </Card>
                      <Card >

                        <Card.Body>
                          <Card.Title> <i className="fa fa-address-book-o "></i> Address</Card.Title>
                          <hr></hr>
                          <Row className="mb-3">
                            <Col>
                              <FloatingLabel controlId="floatingNumber" label="Aadhar Number">
                                <Form.Control type="text" placeholder="Aadhar Number" value={user.aadhar.number ?? ""} isInvalid={!!errors.aadhar} onChange={e => setField('number', e.target.value, 'aadhar')} />
                                <Form.Control.Feedback type='invalid'>{errors.aadhar} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <FloatingLabel controlId="floatingAddr" label="Aadhar address">
                                <Form.Control type="text" placeholder="Aadhar address" value={user.address.address1 ?? ""} isInvalid={!!errors.address1} onChange={e => setField('address1', e.target.value, 'address')} />
                                <Form.Control.Feedback type='invalid'>{errors.address1} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          {/* <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingAddr2" label="Aadhar address 2">
                              <Form.Control type="text" placeholder="Aadhar address 2 " value={user.address.address2 ?? ""} onChange={e => setField('address2', e.target.value, 'address')} />
                            </FloatingLabel>
                          </Col>
                        </Row> */}
                          <Row className="mb-3">
                            <Col>
                              <FloatingLabel controlId="floatingCity" label="City" >
                                <Form.Control type="text" placeholder="City" isInvalid={!!errors.city} value={user.address.city ?? ""} onChange={e => setField('city', e.target.value, 'address')} />
                                <Form.Control.Feedback type='invalid'>{errors.city} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="mb-3">

                            <Col>
                              <Typeahead
                                id="floating-label-state"
                                onChange={(e) => {
                                  setField('state', e[0], 'address')
                                }}
                                clearButton
                                onBlur={() => {
                                  if (!user.address.state) {
                                    typeRef.current?.clear();
                                  }
                                }}
                                options={states}
                                ref={typeRef}
                                placeholder="Choose a state..."
                                renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                  return (

                                    <FloatingLabel controlId="floatingLabel" label="Select a state...">
                                      <Form.Control
                                        {...inputProps}
                                        ref={(node) => {
                                          inputRef(node);
                                          referenceElementRef(node);
                                        }}
                                      />
                                    </FloatingLabel>

                                  );
                                }}
                                selected={user.address.state ? [user.address.state] : []}
                              />
                              {/* <FloatingLabel controlId="floatingState" label="State" >
                              <Form.Select id="floatingState" aria-label="State" isInvalid={!!errors.state} value={user.address.state ?? "0"} onChange={e => setField('state', e.target.value, 'address')}>
                                <option value="0">Select State</option>
                                {
                                  states.map((e, i) => {
                                    return <option key={`state_${i}`} value={e} >{e}</option>
                                  })
                                }
                              </Form.Select>
                              <Form.Control.Feedback type='invalid'>{errors.state} </Form.Control.Feedback>
                            </FloatingLabel> */}
                            </Col>
                            <Col>
                              <FloatingLabel controlId="floatingZip" label="Pincode">
                                <Form.Control type="text" placeholder="Pincode" isInvalid={!!errors.pincode} value={user.address.pincode} onChange={e => setField('pincode', e.target.value, 'address')} />
                                <Form.Control.Feedback type='invalid'>{errors.pincode} </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>

                        </Card.Body>
                      </Card>
                    </Col>
                    <Card className="p-0 col-md-6 col-sm-12 col-xl-4">

                      <Card.Body>
                        <Card.Title className={user?.bank?.verified === 'Y' ? 'text-success' : 'text-danger'}> <i className="fa fa-university "></i> Bank Details - {user?.bank?.verified === 'Y' ? 'Verified' : user?.bank?.verified === 'N' ? 'Unverified' : ''}</Card.Title>
                        <hr></hr>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingbank" label="Bank Name">
                              <Form.Control type="text" placeholder="Bank" isInvalid={!!errors.bankname} value={user.bank.name ?? ""} onChange={e => setField('name', e.target.value, 'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.bankname} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingBranch" label="Branch">
                              <Form.Control type="text" placeholder="Branch" isInvalid={!!errors.branch} value={user.bank.branch ?? ""} onChange={e => setField('branch', e.target.value, 'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.branch} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingIfsc" label="IFSC Code">
                              <Form.Control type="text" placeholder="IFSC Code" isInvalid={!!errors.ifsc} value={user.bank.ifsc ?? ""} onChange={e => setField('ifsc', e.target.value, 'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.ifsc} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingAcc" label="Account Number">
                              <Form.Control type="text" placeholder="Account Number" isInvalid={!!errors.accountnumber} value={user.bank.number ?? ""} onChange={e => setField('number', e.target.value, 'bank')} />
                              <Form.Control.Feedback type='invalid'>{errors.accountnumber} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <FloatingLabel controlId="floatingPan" label="PAN Number">
                              <Form.Control type="text" placeholder="PAN Number" isInvalid={!!errors.pan} value={user.pan ?? ""} onChange={e => setField('pan', e.target.value)} />
                              <Form.Control.Feedback type='invalid'>{errors.pan} </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" id="flexSwitchAttendanceType"
                                checked={user.dailyAttendance === "Y"} onChange={(e) => { setField('dailyAttendance', e.target.checked ? "Y" : "N") }} />
                              <label className="form-check-label" htmlFor="flexSwitchAttendanceType"><b>Require Daily Attendance? </b>
                               
                              </label>
                            </div>
                          </Col>

                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <div className="form-check form-switch">
                              <input className="form-check-input" disabled={!user.id} type="checkbox" id="flexSwitchInvoiceType"
                                checked={user.status === "Y"} onChange={(e) => { setField('status', e.target.checked ? "Y" : "N") }} />
                              <label className="form-check-label" htmlFor="flexSwitchInvoiceType"><b>Is Active? </b>
                                {
                                  user.closedDate && user.status === 'N' ? <sub className="d-flex text-muted">{user.closedDate}</sub> : null
                                }
                              </label>
                            </div>
                          </Col>

                        </Row>


                      </Card.Body>
                    </Card>
                  </Row>
                </Form>
              </Card.Body>
            </Card>


          </Container>)
      } </React.Fragment >);








}