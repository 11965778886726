
import React, { useState } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, isAfter, differenceInCalendarDays } from 'date-fns';
import { Button, Card, Col, Container, Row, Table as BTable, FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Table from "../Table";
import { changeAttendanceReq, resetAttendanceReq, saveAttendanceReqService, loadAllAttendaceReq, loadAttendanceReqService, approveService } from '../../action/attendanceReqAction';
import DatePicker from 'react-date-picker';
import startOfDay from 'date-fns/startOfDay';
import { notification } from '../../action/notificationAction';
import { loadAllAttendace } from '../../action/attendanceAction';
import { MontlyCalendar } from './MonthlyCalendar';
import { loadLeaveByUser } from '../../action/leaveAction';

export default function Attendance({ minDate, permitted, header }) {

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});
    const [createPermission, setCreatePermission] = React.useState(true);
    const [updatePermission, setUpdatePermission] = React.useState(true);

    const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd"));
    const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), "yyyy-MM-dd"));

    const attendances = useSelector(
        (state) => state.attendance.attendances
    );

    const attendanceRequest = useSelector(
        (state) => state.attendanceReq.attendanceReq
    );

    const attendanceRequests = useSelector(
        (state) => state.attendanceReq.attendanceRequests
    );

    const leave = useSelector(
            (state) => state.leave.leave
        );
    

    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("active");

    const theme = useSelector((state) => state.ThemeOptions);

    React.useEffect(() => {
        load(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    React.useEffect(() => {
        dispatch(loadAllAttendace(startDate, endDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate]);


    const handleClose = () => {
        setShow(false);
        dispatch(resetAttendanceReq());

    }

    const attendanceMode = { "OF": "Office", "WFH": "Work From Home", "OS": "Out Station", "SITE": "SITE" };

    const leaveMode = { "SL": "Sick Leave", "CL": "Casual Leave" };

 
    const columns = React.useMemo(
        () => [

            {
                Header: 'User Name',
                accessor: 'user.username',
            },

            {
                Header: 'From Date',
                accessor: (cell) => format(new Date(cell['from']), 'dd-MM-yyyy')
            },
            {
                Header: 'To Date',
                accessor: (cell) => format(new Date(cell['to']), 'dd-MM-yyyy'),
            },
            {
                Header: "Type",
                accessor: 'type'
            },
            {
                Header: "Mode",
                accessor: 'mode'
            },
            {
                Header: "Delayed Req",
                accessor: "delayed"
            }

        ],
        []
    )

    const handleShow = (selected) => {

        setShow(true);


        if (selected?.original.id) {

            loadAttendanceReqService(selected.original.id).then(r => {
                
                dispatch(r);
                dispatch(loadLeaveByUser(selected.original.userid));
                setStartDate(format(startOfMonth(new Date()), "yyyy-MM-dd"));
                setEndDate(format(endOfMonth(new Date()), "yyyy-MM-dd"));
                // if (r?.payment?.vendorid) {
                //     loadVendorPayment(r?.payment?.vendorid, r?.payment?.projectid, r?.payment?.id)
                // }
            }).catch(err => {
                dispatch(err)
            })

            // dispatch(loadPayment(selected.original.id));


        }
        else {
            dispatch(resetAttendanceReq());
        }
    }

    const setField = (field, value, model) => {
        dispatch(changeAttendanceReq({ name: field, value, model }));
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleSubmit = e => {

        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            const diff = differenceInCalendarDays(attendanceRequest.from, attendanceRequest.to)

            if(attendanceRequest.type === 'L' ){

                if((diff> (leave.sl - leave.sl_used) && attendanceRequest.mode==='SL') ||
                (diff> (leave.cl - leave.cl_used) && attendanceRequest.mode==='CL')){

                    dispatch(notification({type:"warning", message:"Available leave is greater than selected range!"}));
                    return 
                }

            }


            let dataToPost = {};
            let method = "POST";

            if (!attendanceRequest.id) {
                dataToPost = {
                    ...attendanceRequest,

                    //   client: isObjectEmpty(project.client)  ? null : project.client          
                };
            }
            else {
                dataToPost = {

                    ...attendanceRequest
                };
                method = "PATCH";
            }

            saveAttendanceReqService(dataToPost, method).then(res => {

                dispatch(resetAttendanceReq());
                dispatch(notification(res));
                handleClose();

                if (!attendanceRequest.id) {
                    load();
                }
            })
                .catch(err => {
                    dispatch(notification(err))
                })


        }
    }

    const handleApprove = e => {

        if (attendanceRequest.id) {

            approveService(attendanceRequest).then(res => {
                dispatch(notification(res));
                load();
                handleClose();
            }).catch(err => {
                dispatch(notification(err));
            });
        }

    }


    const findFormErrors = () => {

        const { type, mode, from, to, remarks } = attendanceRequest
        const newErrors = {}``


        if (!type || type === "0") newErrors.type = 'Select a type!';

        if (!mode || mode === "0") newErrors.mode = 'Select a mode!';

        if (!from || from === "") newErrors.from = 'Select a from date!';

        if (!to || to === '') newErrors.to = 'Select a to date!';

        if (!remarks || remarks === '') newErrors.remarks = 'Remark should not be empty!';


        return newErrors
    }

    const load = () => {

        dispatch(loadAllAttendaceReq(status));
    }

    return (
        < React.Fragment >

            {!show ? (

                <Container fluid >

                    <Card>
                        <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
                            <h6 className="mb-0">Attendance / Leave</h6>
                            {createPermission ?
                                <Button variant="success" className="ms-auto" size="sm" onClick={() => handleShow()} title="New Request">
                                    <i className="fa fa-plus d-md-none d-sm-inline"></i>
                                    <p className="d-none d-md-inline">Add New</p></Button> : ""
                            }

                        </Card.Header>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                    <Table columns={columns} title="Request's" data={attendanceRequests ?? []} edit={handleShow} editText="Double click on the row to view Attendance Request's"
                        status={status} changeStatus={setStatus}>
                    </Table>

                </Container>) :

                (<React.Fragment>

                    <Container fluid>

                        <Card>
                            <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                                <h6 className="mb-0">{`${!attendanceRequest.id ? "New" : "Edit"}`}</h6>
                                <div className="ms-auto">

                                    {
                                        ((attendanceRequest.id && updatePermission) || (!attendanceRequest.id && createPermission)) ?
                                            <Button className="me-2" variant="success" size="sm" title={!attendanceRequest.id ? "Save" : "Update"} onClick={handleSubmit}>

                                                <i className="fa fa-save d-md-none d-sm-inline"></i>
                                                <p className="d-none d-md-inline"> {!attendanceRequest.id ? "Save" : "Update"}</p> </Button> : null

                                    }

                                    <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                                        <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                                        <p className="d-none d-md-inline"> Back</p></Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6} sm={12}>
                                        <BTable striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>
                                                        Desc
                                                    </th>
                                                    <th className='text-center'>
                                                        Bal
                                                    </th>
                                                    <th className='text-center'>
                                                        Used
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Casual Leave</td>
                                                    <td className='text-end'>{(leave?.cl ?? 0) - (leave?.cl_used ??0)}</td>
                                                    <td className='text-end'>{leave?.cl_used ?? 0}</td>                                                    
                                                </tr>
                                                <tr>
                                                    <td>Sick Leave</td>
                                                    <td className='text-end'>{(leave?.sl ?? 0) - (leave?.sl_used ??0)}</td>
                                                    <td className='text-end'>{leave?.sl_used ?? 0}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td>LOP</td>
                                                    <td className='text-end'>0</td>
                                                    <td className='text-center'>-</td>
                                                </tr> */}
                                                <tr>
                                                    <td>Sundays</td>
                                                    <td className='text-end'>{leave?.sundays ?? 0}</td>
                                                    <td className='text-end'>-  </td>
                                                </tr>

                                            </tbody>
                                        </BTable>
                                        <Row>
                                            <Col sm={12} md={6} className="mb-3">
                                                <FloatingLabel controlId="requestType" label="Request Type" >

                                                    <Form.Select id="requestType" aria-label="Request Type" isInvalid={!!errors.type} value={attendanceRequest.type ?? "0"}
                                                        onChange={e => {
                                                            setField("type", e.target.value);
                                                            setField("mode", "0")
                                                        }}
                                                    >
                                                        <option value="0">Select Type</option>
                                                        <option value="L">Leave</option>
                                                        <option value="P">Present</option>

                                                    </Form.Select>
                                                    <Form.Control.Feedback type='invalid'>{errors.type} </Form.Control.Feedback>
                                                </FloatingLabel>

                                            </Col>
                                            {
                                                attendanceRequest?.type ? <Col sm={12} md={6} className="mb-2">
                                                    <FloatingLabel controlId="mode" label="Mode" >

                                                        <Form.Select id="mode" aria-label="Mode" isInvalid={!!errors.mode} value={attendanceRequest.mode ?? "0"}
                                                            onChange={e => setField("mode", e.target.value)}
                                                        >
                                                            <option value="0">Select</option>
                                                            {
                                                                attendanceRequest.type === 'P' ?
                                                                    Object.entries(attendanceMode).map(([key, value], i) => {
                                                                        return <option key={`mode_${i}`} value={key}>{value}</option>
                                                                    }) : Object.entries(leaveMode).map(([key, value], i) => {
                                                                        return <option key={`mode_${i}`} value={key}>{value}</option>
                                                                    })
                                                            }


                                                        </Form.Select>
                                                        <Form.Control.Feedback type='invalid'>{errors.mode} </Form.Control.Feedback>
                                                    </FloatingLabel>

                                                </Col> : ""
                                            }

                                            <Col sm={12} md={6} className='mb-2'>

                                                <div style={{ position: "relative" }} className="dp">
                                                    <DatePicker maxDate={new Date()}
                                                        minDate={minDate ? new Date(minDate) : null}
                                                        id="fromDate"
                                                        value={attendanceRequest.from ? new Date(attendanceRequest.from) : ""}

                                                        format="dd-MM-yyyy"
                                                        calendarIcon={<i className="fa fa-calendar"></i>}
                                                        clearIcon={null}
                                                        className={`form-control ${!!errors.from ? "is-invalid" : ""}`}
                                                        //isInvalid={true}

                                                        onChange={(date) => {
                                                            setField("from", format(date, "yyyy-MM-dd"));
                                                            setField("to", format(date, "yyyy-MM-dd"));
                                                        }}
                                                    ></DatePicker>
                                                    <label className="black-text" htmlFor="fromDate">From Date</label>
                                                </div>

                                            </Col>

                                            <Col sm={12} md={6} className='mb-2'>

                                                <div style={{ position: "relative" }} className="dp">
                                                    <DatePicker
                                                        minDate={attendanceRequest.from ? new Date(attendanceRequest.from) : (minDate ? new Date(minDate) : null)}
                                                        maxDate={attendanceRequest.from ? (addDays(new Date(attendanceRequest.from), 4) <= startOfDay(new Date()) ? addDays(new Date(attendanceRequest.from), 4) : new Date()) : new Date()}
                                                        id="toDate"
                                                        value={attendanceRequest.to ? new Date(attendanceRequest.to) : ""}

                                                        format="dd-MM-yyyy"
                                                        calendarIcon={<i className="fa fa-calendar"></i>}
                                                        clearIcon={null}
                                                        className={`form-control ${!!errors.to ? "is-invalid" : ""}`}
                                                        //isInvalid={true}

                                                        onChange={(date) => setField("to", format(date, "yyyy-MM-dd"))}
                                                    ></DatePicker>
                                                    <label className="black-text" htmlFor="toDate">To Date</label>
                                                </div>

                                            </Col>

                                            <Col sm={12} className="mb-3">
                                                <FloatingLabel controlId="floatingremarks" label="Remarks">
                                                    <Form.Control as="textarea" placeholder="Remarks" style={{ height: '100px' }} isInvalid={!!errors.remarks} value={attendanceRequest.remarks ?? ""} onChange={e => setField("remarks", e.target.value)}></Form.Control>
                                                    <Form.Control.Feedback type='invalid'>{errors.remarks} </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            {
                                                (attendanceRequest?.id) ?
                                                    <Col sm={12} className="mb-3 ">
                                                        <div className="d-grid flex-fill justify-content-md-end pt-3">

                                                            <Button className="me-2 float-end" variant="primary"
                                                                disabled={attendanceRequest?.approve_status !== 'N'}
                                                                size="sm" title="Approve" onClick={handleApprove}> Approve </Button>
                                                        </div>
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={6} sm={12}>

                                        <MontlyCalendar attendanceData={attendances ?? {}} header={header}
                                            setStartDate={setStartDate} setEndDate={setEndDate}
                                            from={attendanceRequest?.from} to={attendanceRequest?.to}
                                            mode={attendanceRequest?.mode} ></MontlyCalendar>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Container>


                </React.Fragment>)}

        </React.Fragment >);

}




