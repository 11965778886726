import {
    LOADATTENDANCEUSER,
    CHANGEATTENDANCEUSER,
    initialState,
    LOADALLATTENDANCE
} from "../constant/constant";

export default function reducer(state = { ...initialState.attendance }, action) {

    switch (action.type) {
        case LOADALLATTENDANCE:
           
            return {...state,attendances:{...action.attendance}}      
        
        case LOADATTENDANCEUSER:
            return {...state,attendance:[...action.attendance]}      
        
      
        default:
            return { ...state };
    }
}