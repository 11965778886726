export const menus = {

    Create: [{
        name: "USER_VIEW",
        link: "/master/user",
        label: "User"
    }, {
        name: "CLIENT_VIEW",
        link: "/master/client",
        label: "Client"
    }, {
        name: "VENDOR_VIEW",
        link: "/master/vendor",
        label: "Vendor"
    }, {
        name: "PROJECT_VIEW",
        link: "/master/project",
        label: "Project"
    }, {
        name: "LABOUR_VIEW",
        link: "/master/labour",
        label: "Labour"
    }, {
        name: "CONTACT_VIEW",
        link: "/master/contact",
        label: "Contact"
    },
    //  {
    //     divider: true
    // },
    {
        name: "CATEGORIES_VIEW",
        link: "/master/category",
        label: "Categories"
    },
    {
        name: "CONFIG_VIEW",
        link: "/master/config",
        label: "Config"
    },


    //  {
    //     name: "MATERIAL_VIEW",
    //     link: "/master/material",
    //     label: "Material"
    // }, {
    //     name: "ACTIVITY_VIEW",
    //     link: "/master/activity",
    //     label: "Activity"
    // }
    {
        name: "ROLEMAPPING_VIEW",
        link: "/master/role",
        label: "Role Mapping"
    },
    {
        name: "FILE_VIEW",
        link: "/file",
        label: "Shared Drive"
    }
    ],
    PO: [
        {
            name: "PO-CLIENT_VIEW",
            link: "/po/client",
            label: "Client"
        },
        {
            name: "PO-VENDOR_VIEW",
            link: "/po/vendor",
            label: "Vendor"
        }
    ],
    Invoice: [
        {
            name: "INVOICE-CLIENT_VIEW",
            link: "/invoice/client",
            label: "Sales"
        }
        , {
            name: "INVOICE-VENDOR_VIEW",
            link: "/invoice/vendor",
            label: "Purchase"
        }
    ],


    Requisitions: [
        {
            name: "PAYMENT-REQ_VIEW",
            link: "/req/payment",
            label: "Payment Request"
        },
        {
            name: "WAGE-REQ_VIEW",
            link: "/payment/mandays",
            label: "Wage Request"
        },
        {
            name: "MANDAYS-ENTRY_VIEW",
            link: "/req/manday",
            label: "Mandays Entry"
        },
        {
            name: "REQ_PENDING",
            link: "/req/pending",
            label: "Pending"
        }
    ],
    Payment: [
        {
            name: "PAYMENT-APPROVAL_VIEW",
            link: "/payment/make",
            label: "Process Payment"
        }, {
            name: "PAYMENT-ALLOCATION_VIEW",
            link: "/payment/allocation",
            label: "Allocation"
        },


        {
            name: "RECEIVABLE_VIEW",
            link: "/payment/received",
            label: "Received"
        }, {
            name: "EXPENSE_VIEW",
            link: "/payment/expense",
            label: "Expense"
        }, {
            name: "TDS_VIEW",
            link: "/tds",
            label: "TDS Entry"
        }, {
            name: "CREDITNOTE_VIEW",
            link: "/payment/creditnote",
            label: "Credit Note"
        },

        {
            name: "SALARY_VIEW",
            link: "/salary",
            label: "Process Salary"
        }
    ],
    Report: [{
        name: "REPORT_SUMMARY",
        link: "/report/summary",
        label: "Summary"
    }, {
        name: "STATEMENT",
        link: "/report",
        label: "Statement"
    }, {
        name: "REPORT_BILL_REGISTER",
        link: "/report/bill",
        label: "Bill Register"
    }, {
        name: "REPORT_DETAILED_SUMMARY",
        link: "/report/detailsummary",
        label: "Detailed Summary"
    }, {
        name: "ACCESS_LOG",
        link: "/report/access",
        label: "Access Log"
    }, {
        name: "REPORT_LABOUR",
        link: "/report/labour",
        label: "Labour Report"
    }],

    Employee: [
        {
            name: "ATTENDANCE",
            link: "/attendance",
            label: "Attendance"
        },
        {
            name: "CALENDAR",
            link: "/calendar",
            label: "Calendar"
        },        
        {
            name: "HOLIDAY",
            link: "/holiday",
            label: "Holiday & Leave Allocation"
        },
        

    ]
}

export const icons = {



}