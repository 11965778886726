import { combineReducers } from 'redux';
import userReducer from "../reducer/userReducer";
import notificationReducer from '../reducer/notificationReducer';
import loginReducer from '../reducer/loginReducer';
import loaderReducer from '../reducer/loaderReducer';
import clientReducer from '../reducer/clientReducer';
import vendorReducer from '../reducer/vendorReducer';
import projectReducer from '../reducer/projectReducer';
import lookupReducer from '../reducer/lookupReducer';
import labourReducer from '../reducer/labourReducer';
import categoryReducer from '../reducer/categoryReducer';

import materialReducer from '../reducer/materialReducer';
import activityReducer from '../reducer/activityReducer';
import poReducer from '../reducer/poReducer';
import paymentReducer from '../reducer/paymentReducer';
import expenseReducer from '../reducer/expenseReducer';
import invoiceReducer from '../reducer/invoiceReducer';
import tdsReducer from '../reducer/tdsReducer';
import reportReducer from '../reducer/reportReducer';
import walletReducer from '../reducer/walletReducer';
import roleReducer from '../reducer/roleReducer';
import themeReducer from '../reducer/themeReducer';
import configReducer from '../reducer/configReducer';
import costcenterReducer from '../reducer/costCenterReducer';
import dashboardReducer from '../reducer/dashboardReducer';
import contactReducer from '../reducer/contactReducer';
import creditnoteReducer from '../reducer/creditReducer';
import atttendanceReducer from '../reducer/attendanceReducer';
import atttendanceReqReducer from '../reducer/attendanceReqReducer';
import leaveReducer from '../reducer/leaveReducer';
import holidayReducer from '../reducer/holidayReducer';

export const rootReducer = combineReducers({
    wallet: walletReducer,
    user: userReducer,
    notification:notificationReducer,
    auth_user:loginReducer,
    client:clientReducer,
    vendor: vendorReducer,
    project:projectReducer,
    fetch:loaderReducer,
    lookup: lookupReducer,
    labour: labourReducer,
    category: categoryReducer,
    material: materialReducer,
    activity: activityReducer,
    po: poReducer,
    payment: paymentReducer,
    expense: expenseReducer,
    invoice: invoiceReducer,
    tds: tdsReducer,
    report: reportReducer,
    roleMapping: roleReducer,
    ThemeOptions: themeReducer,
    config: configReducer,
    costcenter: costcenterReducer,
    dashboard:dashboardReducer,
    contact: contactReducer,
    creditnote: creditnoteReducer,
    attendance: atttendanceReducer,
    attendanceReq: atttendanceReqReducer,
    leave: leaveReducer,
    holiday: holidayReducer
});
