import {
    LOADALLATTENDANCEREQ,
    CHANGEATTENDANCEREQ,
    initialState,
    LOADATTENDANCEREQ,
    RESETATTENDANCEREQ,
} from "../constant/constant";

export default function reducer(state = { ...initialState.attendanceReq }, action) {

    switch (action.type) {
        case LOADALLATTENDANCEREQ:

            return { ...state, attendanceRequests: [ ...action.attendanceRequests ] }

        case LOADATTENDANCEREQ:
            return { ...state, attendanceReq: { ...action.attendanceReq } }

        case CHANGEATTENDANCEREQ:
            return { ...state, attendanceReq: { ...state.attendanceReq, [action.name]: action.value } };

        case RESETATTENDANCEREQ:
            return { ...state, attendanceReq: { ...initialState.attendanceReq.attendanceReq } };
        default:
            return { ...state };
    }
}