import {  CHANGEATTENDANCEUSER, LOADALLATTENDANCE, LOADATTENDANCEUSER ,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";


function loadAllAttendanceService(fromDate, toDate){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/attendance?fromDate=${fromDate}&toDate=${toDate}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOADALLATTENDANCE, attendance: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load attendance data",variant:"danger",err}  })
            });
    });
  
  }

function loadAttendanceService(){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/attendance/user`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADATTENDANCEUSER, attendance: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load attendance data",variant:"danger",err}  })
          });
  });

}

export function saveAttendanceService(data,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "POST",
          "url": `/api/attendance`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(data)
        
      }).then(response => {

         if(response.data){
               
        //   store.dispatch({
        //     type: SET_NOTIFICATION, notification:
               resolve({ message: `Attendance entry saved`, variant: "success", err: "" })
            // });
            // resolve({ type: RESETCREDIT});
         }
      })
          .catch(err => {
              
              reject({message:"Failed to save attendance data",variant:"danger",err}  )
          });
  });

}



export const loadAttendace=()=>{

  return dispatch => {

      loadAttendanceService()
          .then(response => dispatch(response))
          .catch(err => dispatch(err));
  }
}

export const loadAllAttendace=(fromDate, toDate)=>{

    return dispatch => {
  
        loadAllAttendanceService(fromDate, toDate)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
  }

  
  

export const changeAttendance=({status})=>{

  return dispatch => {
    dispatch({type:CHANGEATTENDANCEUSER,status});
}
  



  
}



