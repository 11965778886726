import { LOADALLLEAVE, LOADLEAVE, CHANGELEAVE, RESETLEAVE, SET_NOTIFICATION } from "../constant/constant";
import { pAxios } from "..";


function loadAllLeaveService(status) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/leave`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLLEAVE, leaves: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load leave data", variant: "danger", err } })
            });
    });

}

export function loadLeaveService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/leave/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADLEAVE, leave: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load leave data", variant: "danger", err } })
            });
    });

}

function loadLeaveByUserService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/leave/user/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADLEAVE, leave: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load leave data", variant: "danger", err } })
            });
    });

}

export function saveLeaveService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/leave`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                //   store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Leave entry saved`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETCREDIT});
            }
        })
            .catch(err => {

                reject({ message: "Failed to save leave data", variant: "danger", err })
            });
    });

}

export const loadLeave = (id) => {

    return dispatch => {

        loadLeaveService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}

export const loadLeaveByUser = (user) => {

    return dispatch => {

        loadLeaveByUserService(user)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}


export const loadAllLeave = (status) => {

    return dispatch => {

        loadAllLeaveService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}




export const changeLeave = ({ name, value }) => {

    return dispatch => {
        dispatch({ type: CHANGELEAVE, name, value });
    }

}

export const resetLeave = () => {

    return dispatch => {
        dispatch({ type: RESETLEAVE, });
    }

}



