import { LOADALLHOLIDAY, LOADHOLIDAY, CHANGEHOLIDAY, RESETHOLIDAY, SET_NOTIFICATION } from "../constant/constant";
import { pAxios } from "..";


function loadAllHolidayService(status) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/holiday?year=${new Date().getFullYear()}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLHOLIDAY, holidays: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load leave data", variant: "danger", err } })
            });
    });

}

export function loadHolidayService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/holiday/${id}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADHOLIDAY, holiday: (response.data) });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load leave data", variant: "danger", err } })
            });
    });

}


export function saveHolidayService(data, method) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/holiday`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(data)

        }).then(response => {

            if (response.data) {

                //   store.dispatch({
                //     type: SET_NOTIFICATION, notification:
                resolve({ message: `Holiday entry saved`, variant: "success", err: "" })
                // });
                // resolve({ type: RESETCREDIT});
            }
        })
            .catch(err => {

                reject({ message: "Failed to save leave data", variant: "danger", err })
            });
    });

}

export const loadHoliday = (id) => {

    return dispatch => {

        loadHolidayService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}


export const loadAllHoliday = (status) => {

    return dispatch => {

        loadAllHolidayService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    }
}




export const changeHoliday = ({ name, value }) => {

    return dispatch => {
        dispatch({ type: CHANGEHOLIDAY, name, value });
    }

}

export const resetHoliday = () => {

    return dispatch => {
        dispatch({ type: RESETHOLIDAY, });
    }

}



