import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Card, Row, Col, Button, FloatingLabel, Table as BTable, Modal } from "react-bootstrap";

import Table from "./Table";
// import { loadAllVendor, resetVendors } from "../action/vendorAction";
// import { loadAllProject, resetProjects } from "../action/projectAction";
import { allocateInvoiceService, markAsAllocatedService } from "../action/invoiceAction ";
import { loadAllInvoice, resetInvoice } from "../action/invoiceAction ";
import { notification, warningNotification } from "../action/notificationAction";
import { numberCheck } from "../constant/numberCheck";
import { loadAllPayment } from "../action/paymentAction";
import { format } from "date-fns";
import { Confirm } from "./Confirm";
import Invoice from "./Invoice";
import { savePendingService } from "../action/reportAction";

import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatCurrency } from "../constant/format";
import { Typeahead } from "react-bootstrap-typeahead";
import { filterClientsSearch, filterProjectsSearch, filterVendorsSearch } from "../constant/filterTypeahead";
import { pAxios } from "..";
import fileDownload from 'js-file-download'
// import { loadAllUser,  getUserPassword } from "../action/userAction";

export default function Allocation({ permitted }) {

  const proTypeRef = React.useRef(null);
  const venTypeRef = React.useRef(null);
  const cltTypeRef = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [closePermission, setClosePermission] = React.useState(permitted(`PAYMENT-ALLOCATION_CLOSE`));
  // eslint-disable-next-line no-unused-vars
  const [ownPermission, setOwnPermission] = React.useState(permitted(`PAYMENT-ALLOCATION_OWN`));
  // eslint-disable-next-line no-unused-vars
  const [markAllocated, setMarkAllocated] = React.useState(permitted(`PAYMENT-ALLOCATION_MARKAS_ALLOCATED`));
  

  const dispatch = useDispatch();


  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [side, setSide] = useState('client');


  const [selected, setSelected] = useState(null);
  const [allocatedTotal, setAllocatedTotal] = useState(0);
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });
  const [showModal, setShowModal] = useState(false);
  const [execludePaid, setExecludePaid] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  // const [selectedVendor,setSelectedVendor] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedSide, setSelectedSide] = useState("0");


  const clients = useSelector(
    (state) => state.lookup.client
  );


  const payments = useSelector(
    (state) => state.payment.payments
  );

  const invoices = useSelector(
    (state) => state.invoice.invoices
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );


  const projects = useSelector(
    (state) => state.lookup.project
  );

  const handleShow = (seleted) => {

    setSelected(null);
    setAllocatedTotal(0);

    if (seleted?.original?.id) {

      setShow(true);
      setSelected(seleted.original);
      dispatch(loadAllInvoice(true, (seleted.original.paymenton === 'CLIENT' ? 'CLIENT' : 'VENDOR'),
        (seleted.original.paymenton === 'CLIENT' ? seleted.original.clientid : seleted.original.vendorid),
        seleted.original.projectid, null, false, execludePaid));
    }

  }

  const loadInvoices = () => {
    if (selected) {

      dispatch(loadAllInvoice(true, (selected.paymenton === 'CLIENT' ? 'CLIENT' : 'VENDOR'),
        (selected.paymenton === 'CLIENT' ? selected.clientid : selected.vendorid),
        selected.projectid, null, false, execludePaid));
    }
  }

  const handleClose = (skipLoadPayments) => {
    setShow(false);
    dispatch(resetInvoice());
    
    if (!skipLoadPayments) {
      load();
    }

    setSelected(null);
    setAllocatedTotal(0);
    setAllocation({});
  }

  const handleModalClose = () => {

    setShowModal(false);
    loadInvoices();
  }


  React.useEffect(() => {
    setSelectedProjectId(null);
    proTypeRef?.current?.clear();
  },[costcenter]);

  React.useEffect(() => {

    if (ownPermission !== undefined) {
      load();
    }
    handleClose(true);

  }, [dispatch, ownPermission, costcenter]);

  React.useEffect(() => {
    loadInvoices();
  }, [execludePaid]);

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])


  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id'
      },

      {
        Header: 'Project Name',
        accessor: 'projectname',
      },

      {
        Header: 'Client/Vendor Name',
        accessor: 'dname',
      },
      {
        Header: 'Payment On',
        accessor: 'paymenton',
      },
      {
        Header: "Date",
        accessor: (cell) => format(new Date(cell['date']), 'dd-MM-yyyy')
      },
      {
        Header: "Mode",
        accessor: "mode_name"
      },
      {
        Header: "Req By",
        accessor: 'requested_by'
      },
      {
        Header: 'Total Payment',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true

      },
      {
        Header: 'Allocated',
        accessor: (cell) => formatCurrency(Number(cell['allocated'] ?? 0) + Number(cell['tds']??0)),
        className: 'text-end',
        hideFilter: true
      },
      {
        Header: 'Balance',
        accessor: (cell) => formatCurrency( Number(cell['gross'] ?? 0) - (Number(cell['allocated'] ?? 0) + Number(cell['tds']??0)) ),
        className: 'text-end',
        hideFilter: true
      }
    ],
    []
  )

  const [allocation, setAllocation] = useState({});

  const handleSubmit = e => {

    e.preventDefault();


    if (selected && allocatedTotal > 0) {
      allocateInvoiceService(allocation, side, selected.id, selected.mode_name)
        .then(res => {
          dispatch(notification(res));
          handleClose();
        })
        .catch(err => {
          dispatch(notification(err));
          handleClose();
        })

    }
    else {
      dispatch(warningNotification("Allocated amount should be greater than 0"));
    }




  }

  const handleChange = (e, { id, ...inv }) => {

    const balance = (Number(inv.gross ?? 0) - Number(inv.allocated ?? 0));
    const available = ((Number(selected.gross) ?? 0) - (Number(selected.allocated) ?? 0))
    const temp = { ...allocation, [id]: e.target.value }
    const total = Object.keys(temp).map(e => Number(temp[e])).reduce((prev, curr) => prev + curr, 0);

    if (balance < Number(e.target.value)) {
      dispatch(warningNotification("Allocation amount should not be greater than unallocated value"))
    }

    else if (selected && Number(available) >= Number(total)) {
      setAllocation({ ...allocation, [id]: e.target.value  });
      setAllocatedTotal(total);
    }
    else {
      dispatch(warningNotification("Total allocation amount should not be greater than total unallocated value"))
    }
  }

  const closePayment = (close) => {

    if (close) {
      if (closePermission) {

        markAsAllocatedService(selected.id, 'Allocation')
          .then(res => {
            dispatch(notification(res));
            handleClose();
          })
          .catch(err => {
            dispatch(notification(err));
            handleClose();
          })
      }
      else {
        savePendingService({
          table: 'Payment',
          pk_id: selected.id,
          type: 'Allocation close',
          amount: (selected.gross - selected.allocated).toFixed(2),
          project: selected.projectid,
          name: selected.name,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          screen_name: 'Allocation'

        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }

    }

    setShowConfirm({ show: false, callBackData: null })


  };

  const load = () =>{

    const postData = genPostData();
   
    dispatch(loadAllPayment(postData));
  }

  const genPostData = ()=>{
    let postData = {unallocated: true, ownPermission};

    if(costcenter && costcenter!=='0'){
      postData['costcenter']=costcenter;
    }

    if(selectedProjectId && selectedId!=null && selectedSide){
      postData['projectid']= selectedProjectId;
      postData[`${selectedSide.toLowerCase()}id`] = selectedId;
    }

    else if(selectedProjectId){
      postData['projectid']= selectedProjectId;
    }

    else if(selectedId!=null){
      postData[`${selectedSide.toLowerCase()}id`] = selectedId;
    }

    return postData;
  }

  const download =() =>{
 
    const postData = genPostData();
    
    const url = Object.keys(postData).filter((k) => {
      return postData[k] !== null
    }).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(postData[k])
    }).join('&')


    pAxios({
      "method": "GET",
      "url": "/api/auth",

    }).then(r => {

      pAxios.get(`/api/report/allocation?${url}`, {
        responseType: 'blob',
      })
        .then((res) => {
          fileDownload(res.data, `Unallocated.XLSX`)
        })
    })
  }

  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>

      {!show ? (

        <Container fluid >

          <Card>
            <Card.Header className={`d-flex ${theme.headerBackgroundColor}`}>
              <h6 className="mb-0">Payment Allocation </h6>

            </Card.Header>
            <Card.Body>
              <Row >
                
                {/* 
                <Col sm={12} md={3}>
                  <Typeahead
                            id="floating-label-ven-filter"
                            onChange={(e) => {
                              setSelectedVendor(e[0]?.id);
                              
                            }}
                            clearButton
                            onBlur={() => {
                              if (selectedVendor === null) {
                                venTypeRef.current?.clear();
                                setSelectedVendor(null);
                              }
                            }}

                            labelKey="name"
                            options={filterVendorsSearch(vendors)}
                            ref={venTypeRef}
                            placeholder="Choose a vendor"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingVendorIdFilter" label="Select a vendor">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                </FloatingLabel>

                              );
                            }}
                            selected={selectedVendor !== null ? vendors.filter(e => e.id === selectedVendor) : []}
                          />
                </Col> */}

                  <React.Fragment>
                    <Col sm={12} md={3} >
                      <FloatingLabel controlId="floatingSide" label={`Select Client/Vendor`}>
                        <Form.Select
                          value={selectedSide}
                          onChange={e => {
                            setSelectedSide(e.target.value)
                            setSelectedProjectId(null);
                            setSelectedId(null);
                          }}
                        >
                          <option value="0"> Select side</option>
                          <option value="Client">Client</option>
                          <option value="Vendor">Vendor</option>
                        

                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    {selectedSide === "0" ? null :

                      <Col sm={12} md={3} >
                        {selectedSide === "Client" ? (
                          <Typeahead
                            id="floating-label-clt-filter"
                            onChange={(e) => {
                              setSelectedId(e[0]?.id);
                              setSelectedProjectId(null);                              
                            }}
                            onBlur={() => {
                              if (!selectedId) {
                                cltTypeRef.current?.clear();
                                setSelectedId(null)
                                setSelectedProjectId(null);                                
                              }
                            }}
                            clearButton
                            labelKey="name"
                            options={filterClientsSearch(clients,costcenter)}
                            ref={cltTypeRef}
                            placeholder="Choose a client"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingClientFilter" label="Select a client">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                </FloatingLabel>

                              );
                            }}
                            selected={selectedId ? clients.filter(e => Number(e.id) === Number(selectedId)) : []}
                          />) :
                         
                          (<Typeahead
                            id="floating-label-ven-filter"
                            onChange={(e) => {
                              setSelectedId(e[0]?.id);                              
                            }}
                            clearButton
                            onBlur={() => {
                              if (selectedId === null) {
                                venTypeRef.current?.clear();
                                setSelectedId(null);                                
                              }
                            }}

                            labelKey="name"
                            options={filterVendorsSearch(vendors)}
                            ref={venTypeRef}
                            placeholder="Choose a vendor"
                            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                              return (

                                <FloatingLabel controlId="floatingVendorIdFilter" label="Select a vendor">
                                  <Form.Control
                                    {...inputProps}
                                    ref={(node) => {
                                      inputRef(node);
                                      referenceElementRef(node);
                                    }}
                                  />
                                </FloatingLabel>

                              );
                            }}
                            selected={selectedId !== null ? vendors.filter(e => Number(e.id) === Number(selectedId)) : []}
                          />)
                        }
                      </Col>
                    }
                  </React.Fragment>
                  <Col sm={12} md={3}>
                  <Typeahead
                    id="floating-label-pro-filter"
                    onChange={(e) => {
                      setSelectedProjectId(e[0]?.id)
                    }}
                    clearButton
                    labelKey="name"
                    onBlur={() => {
                      if (!selectedProjectId) {
                        proTypeRef.current?.clear();
                      }
                    }}
                    
                    options={projects && (selectedSide !== "Client" || (selectedId!=null)) ?
                    filterProjectsSearch(projects, costcenter).filter(e => selectedSide !== "Client" || Number(e.clientid) === Number(selectedId)) : []}

                    ref={proTypeRef}
                    placeholder="Choose A Project"
                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                      return (

                        <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                          <Form.Control
                            {...inputProps}
                            ref={(node) => {
                              inputRef(node);
                              referenceElementRef(node);
                            }}
                          />
                        </FloatingLabel>

                      );
                    }}
                    selected={selectedProjectId ? projects.filter(e => e.id === selectedProjectId) : []}
                  />
                </Col>

                <Col >
                  {
                    !show ? (<div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">

                      <Button variant="primary" size="sm" onClick={() => load()}>Search</Button>
                      <Button variant="success" size="sm" onClick={() => download()}>Download</Button>
                    </div>) : ""}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Table columns={columns} data={payments} edit={handleShow} editText="Double click on the row to view invoice's">
          </Table>

        </Container>)
        : (


          <Container fluid>
            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                <h6 className="flex-fill text-start">Invoice's</h6>
                <div>


                  <Button className="me-1" variant="success" size="sm" onClick={handleSubmit} title="Save">
                    <i className="fa fa-save d-md-none d-sm-inline" ></i>
                    <p className="d-none d-md-inline">Save</p>
                  </Button>



                  <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                    <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                    <p className="d-none d-md-inline"> Back</p>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>

                <Row className="mb-3">
                  <Col xs={12} md={2}>
                    <FloatingLabel controlId="floatingAllocationPending" label="Pending Allocated">
                      <Form.Control type="text" className="text-end"
                        placeholder="Pending Amount"
                        value={selected ? formatCurrency((Number(selected.gross) ?? 0) - (Number(selected.allocated) ?? 0)) : 0} readOnly />
                    </FloatingLabel>

                  </Col>

                  <Col xs={12} md={2}>
                    <FloatingLabel controlId="floatingPending" label="Allocated Amount">
                      <Form.Control type="text" className="text-end" placeholder="Allocated Amount" value={formatCurrency(allocatedTotal ?? 0)} readOnly />
                    </FloatingLabel>

                  </Col>

                  <Col xs={12} md={2}>
                    <FloatingLabel controlId="floatingVendor" label="Vendor Name">
                      <Form.Control type="text" placeholder="Vendor Name" value={selected.dname ?? ""} readOnly />
                    </FloatingLabel>

                  </Col>
                  <Col sm={12} md={2}>
                    <div className="form-check form-switch pt-3">
                      <input className="form-check-input" type="checkbox" id="flexSwitchGeneralExpense"
                        checked={execludePaid}
                        onChange={(e) => {
                          setExecludePaid(e.target.checked)

                        }} />
                      <label className="form-check-label" htmlFor="flexSwitchGeneralExpense"><b>Exclude Paid  </b></label>
                    </div>
                  </Col>
                  <Col>


                    <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">


                      {

                        <Button variant="primary" size="sm" onClick={() => { setShowModal(true) }}>New Invoice</Button>

                      }



                    </div>
                  </Col>

                </Row>
                {/* <Table columns={columnsInvoice} data={invoices} cssName="allocation-inv"></Table> */}

                <BTable striped bordered hover responsive="md" size='md' className="allocation-inv">
                  <thead>
                    <tr><th className="text-center" style={{ width: "50px" }}>#</th>
                      <th className="text-center">Project</th>
                      <th className="text-center">Type</th>
                      <th className="text-center" >Date</th>
                      <th className="text-center" >Inv-Num</th>
                      <th className="text-center" >Gross</th>
                      <th className="text-center" >GST</th>
                      <th className="text-center" >TDS</th>
                      <th className="text-center" >Balance</th>
                      <th className="text-center" style={{ width: "250px" }}>Action  </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoices.map((inv, idx) => {
                        return <tr key={`tr_${idx}`}>
                          <td>{inv.id}</td>
                          <td>{inv.projectname}</td>
                          <td>{inv['openinvoice'] === 'Y' ? "Open" : inv['type']}</td>
                          <td>{inv.date}</td>
                          <td className="text-end">{inv.number}</td>
                          <td className="text-end">{formatCurrency(inv.gross)}</td>
                          <td className="text-end">{formatCurrency(inv.gst)}</td>
                          <td className="text-end">{formatCurrency(inv.tds)}</td>
                          <td className="text-end fw-bold">{formatCurrency((Number(inv.gross ?? 0) - ( Number(inv.allocated ?? 0) + Number(inv.tds??0)  )))}</td>
                          <td>{(Number(inv.gross ?? 0) - (Number(inv.allocated ?? 0) + Number(inv.tds??0)) ) > 0 ?
                            <input type="text" name="allcoationTxt"
                              onKeyPress={numberCheck} className="form-control"
                              value={allocation[inv.id]}

                              onChange={e => handleChange(e, inv)} /> : <span className="text-center align-middle">Fully allocated</span>
                          }</td>
                        </tr>
                      })
                    }

                  </tbody>
                </BTable>
                {
                  markAllocated ? <Row>
                  <Col>

                    <div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-start  h-100 align-items-end">
                      <Button className="me-1" variant="danger" size="sm" onClick={() => {
                        setShowConfirm({ show: true, callBackData: selected.id })
                      }}>
                        Mark As Allocated
                      </Button>
                    </div>
                  </Col>

                </Row> : null
                }
                



              </Card.Body>
            </Card>


          </Container>



        )
      }
      <Confirm show={showConfirm} confirmation={closePermission ? "Are you sure? Once closed, it can't be reversed." : "You are not allowed to close, however this will be created as a requested to elevated users!"} confirmCallBack={closePayment}></Confirm>

      <Modal show={showModal} onHide={() => { handleModalClose() }} dialogClassName="modal-90w">
        <Modal.Header closeButton >
          <Modal.Title>Create new invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Invoice permitted={permitted}
            entity={selected?.paymenton === 'CLIENT' ? 'Client' : "Vendor"}
            directAdd={true}
            dclient={selected ? selected.clientid : null}
            dvendor={selected ? selected.vendorid : null}
            dproject={selected ? selected.projectid : null}
            dvendorname={selected ? selected.vendor_name : null}
            dcallback={handleModalClose}
          />
        </Modal.Body>

      </Modal>

    </React.Fragment >);








}