
import React, { } from 'react';
import { Card, Container, Table as BTable, Modal, Row, Col, FloatingLabel, Form, Button, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Table from "../Table";
import { changeLeave, loadAllLeave, loadLeaveService, resetLeave, saveLeaveService } from '../../action/leaveAction';
import { notification } from '../../action/notificationAction';
import DatePicker from 'react-date-picker';
import { format } from 'date-fns';
import { changeHoliday, loadAllHoliday, resetHoliday, saveHolidayService } from '../../action/holidayAction';


export default function Holiday({ minDate, permitted, header }) {

    const dispatch = useDispatch();

    const [showModal, setShowModal] = React.useState(false);

    const [tab, setTab] = React.useState('leave');

    const theme = useSelector((state) => state.ThemeOptions);

    const [errors, setErrors] = React.useState({});

    const leaves = useSelector(
        (state) => state.leave.leaves
    );

    const leave = useSelector(
        (state) => state.leave.leave
    );

    const holidays = useSelector(
        (state) => state.holiday.holidays
    );


    const holiday = useSelector(
        (state) => state.holiday.holiday
    );

    React.useEffect(() => {
        dispatch(loadAllLeave());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const columns = React.useMemo(
        () => [

            {
                Header: 'User Name',
                accessor: 'user.username',
            },

            {
                Header: 'Casual Leave',
                accessor: 'cl',
                className: 'text-end',
            },
            {
                Header: 'CL Accumulated',
                accessor: 'cl_accumulated',
                className: 'text-end',
            },
            {
                Header: 'CL Used',
                accessor: 'cl_used',
                className: 'text-end',
            },
            {
                Header: "Sick Leave",
                accessor: 'sl',
                className: 'text-end',

            },
            {
                Header: 'SL Accumulated',
                accessor: 'sl_accumulated',
                className: 'text-end',
            },
            {
                Header: "SL Used",
                accessor: 'sl_used',
                className: 'text-end',
            },
            {
                Header: "Sunday",
                accessor: 'sundays',
                className: 'text-end',
            }

        ],
        []
    );

    const holidayCol = React.useMemo(
        () => [

            

            {
                Header: 'Holida Desc',
                accessor: 'description'
            },
            {
                Header: 'Date',
                accessor: 'date',
            },
            {
                Header: 'Action',
                accessor: () => ""
            }

        ],
        []
    );

    const handleShow = (selected) => {


        if (selected?.original.id) {

            loadLeaveService(selected.original.id).then(r => {

                dispatch(r);
                setShowModal(true);
                // if (r?.payment?.vendorid) {
                //     loadVendorPayment(r?.payment?.vendorid, r?.payment?.projectid, r?.payment?.id)
                // }
            }).catch(err => {
                dispatch(err)
            })

        }
        else {
            dispatch(resetLeave());
        }

    }

    const handleModalClose = () => {

        setShowModal(false);
        dispatch(resetLeave());
        dispatch(loadAllLeave());
    }

    const setField = (field, value, model) => {
        dispatch(changeLeave({ name: field, value, model }));
        // Check and see if errors exist, and remove them from the error object:

    }


    const setFieldHoliday = (field, value) => {
        dispatch(changeHoliday({ name: field, value }));
        // Check and see if errors exist, and remove them from the error object:

    }

    const handleSubmit = () => {

        saveLeaveService(leave, 'POST').then(res => {

            dispatch(resetLeave());
            dispatch(notification(res));
            handleModalClose();

        })
            .catch(err => {
                dispatch(notification(err))
            })

    }

    const handleSave = e => {

        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            let dataToPost = {};
            let method = "POST";

            if (!holiday.id) {
                dataToPost = {
                    ...holiday, year: new Date().getFullYear()

                    //   client: isObjectEmpty(project.client)  ? null : project.client          
                };
            }
            else {
                dataToPost = {

                    ...holiday
                };
                method = "PATCH";
            }

            saveHolidayService(dataToPost, method).then(res => {

                dispatch(resetHoliday());
                dispatch(notification(res));
                dispatch(loadAllHoliday());

            })
                .catch(err => {
                    dispatch(notification(err))
                })


        }
    }

    const findFormErrors = () => {

        const { date, description } = holiday
        const newErrors = {}


        if (!date || date === "0") newErrors.date = 'Date should not be empty';

        if (!description || description === "0") newErrors.description = 'Description should not be empty';


        return newErrors
    }

    return (
        < React.Fragment >
            <Container fluid>

                <Card>
                    <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
                        <h6 className="mb-0">Holiday Matrix</h6>
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <Tabs activeKey={tab}
                            onSelect={(k) => {
                                setTab(k);
                                if (k === 'leave') {
                                    dispatch(loadAllLeave());
                                }
                                else {
                                    dispatch(loadAllHoliday());
                                }

                            }} className="mb-0 pt-2">
                            <Tab eventKey="leave" title="Leaves" className="p-2">
                                <Table columns={columns} data={leaves ?? []} edit={handleShow} editText="Double click on the row to edit leave's">
                                </Table>
                            </Tab>
                            <Tab eventKey="holiday" title="Holiday" className="p-2">
                                <Container fluid>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <Row>
                                                <Col sm={12} md={6} className='mb-2'>

                                                    <div style={{ position: "relative" }} className="dp">
                                                        <DatePicker
                                                            minDate={minDate ? new Date(minDate) : null}
                                                            id="date"
                                                            value={holiday.date ?? null}

                                                            format="dd-MM-yyyy"
                                                            calendarIcon={<i className="fa fa-calendar"></i>}
                                                            clearIcon={null}
                                                            className={`form-control ${!!errors.to ? "is-invalid" : ""}`}
                                                            //isInvalid={true}

                                                            onChange={(date) => setFieldHoliday("date", format(date, "yyyy-MM-dd"))}
                                                        ></DatePicker>
                                                        <label className="black-text" htmlFor="date">Holiday Date</label>
                                                    </div>

                                                </Col>

                                                <Col sm={12} md={6} className="mb-3">
                                                    <FloatingLabel controlId="floatingDesc" label="Description">
                                                        <Form.Control type="text" placeholder="Description" isInvalid={!!errors.description} value={holiday.description ?? ""} onChange={e => setFieldHoliday("description", e.target.value)}></Form.Control>
                                                        <Form.Control.Feedback type='invalid'>{errors.description} </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col sm={12} className="mb-3 ">
                                                    <div className="d-grid flex-fill justify-content-md-end pt-3">

                                                        <Button className="me-2 float-end" variant="primary"
                                                            size="sm" title="Save" onClick={handleSave}> Add </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Table columns={holidayCol} data={holidays ?? []}>
                                            </Table>

                                        </Col>
                                    </Row>
                                </Container>

                            </Tab>

                        </Tabs>

                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showModal} onHide={() => { handleModalClose() }} >
                <Modal.Header closeButton >
                    <Modal.Title>{leave?.user?.username ?? ""} Leave Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingCl" label="Casual Leave">
                                    <Form.Control type="number" placeholder="Casual Leave" max={20} value={leave?.cl ?? "0"} onChange={e => setField('cl', e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingSl" label="Sick Leave">
                                    <Form.Control type="number" placeholder="Sick Leave" max={20} value={leave?.sl ?? "0"} onChange={e => setField('sl', e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingClAccumulated" label="Casual Leave Accumulated">
                                    <Form.Control type="number" placeholder="Casual Leave Accumulated" value={leave?.cl_accumulated ?? "0"} disabled={true} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingSlAccumulated" label="Sick Leave Accumulated">
                                    <Form.Control type="number" placeholder="Casual Leave Accumulated" value={leave?.sl_accumulated ?? "0"} disabled={true} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingClUsed" label="Casual Leave Used">
                                    <Form.Control type="number" placeholder="Casual Leave Used" value={leave?.cl_used ?? "0"} disabled={true} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingSlUsed" label="Sick Leave Used">
                                    <Form.Control type="number" placeholder="Sick Leave Used" value={leave?.sl_used ?? "0"} disabled={true} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6} className='mb-1'>
                                <FloatingLabel controlId="floatingSunday" label="Sundays">
                                    <Form.Control type="number" placeholder="Sunday" value={leave?.sundays ?? "0"} disabled={true} />
                                </FloatingLabel>
                            </Col>

                            <Col xs={12} sm={6} className="mb-3 ">
                                <div className="d-grid flex-fill justify-content-md-end pt-3">

                                    <Button className="me-2 float-end" variant="success"
                                        size="sm" title="Save" onClick={handleSubmit}> Save </Button>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>

            </Modal>


        </React.Fragment >);

}


